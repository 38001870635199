// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 900px) {
    .responsive-container {
        flex-direction: column;
    }

    .nucleo-section {
        width: 100%; /* Full width in smaller viewports */
    }

}


@media (min-width: 900px) {
    .nucleo-section {
        width: 50%;
    }

}
`, "",{"version":3,"sources":["webpack://./src/views/distribuicao/distribuicao.css"],"names":[],"mappings":"AAAA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,WAAW,EAAE,oCAAoC;IACrD;;AAEJ;;;AAGA;IACI;QACI,UAAU;IACd;;AAEJ","sourcesContent":["@media (max-width: 900px) {\r\n    .responsive-container {\r\n        flex-direction: column;\r\n    }\r\n\r\n    .nucleo-section {\r\n        width: 100%; /* Full width in smaller viewports */\r\n    }\r\n\r\n}\r\n\r\n\r\n@media (min-width: 900px) {\r\n    .nucleo-section {\r\n        width: 50%;\r\n    }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
