import { useEffect, useState } from "react";
import {
    Card,
    CardFooter,
    CardHeader,
    Container,
    Label,
    Media,
    Row,
    Table,
} from "reactstrap";

import { locale } from "./translate";

import * as locales from 'rsuite/locales';

import { Input, MaskedInput, Message, Modal, useToaster, DatePicker, Button, CustomProvider } from "rsuite";
import Select from 'rsuite/SelectPicker'
import { getUsersList } from "services/userService";

export default function Logs() {

    const [users, setUsers] = useState([])

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const translateData = Object.keys(locales).map(key => ({
        key,
        value: locales[key]
      }));

    useEffect(() => {
        const fetchData = async () => {
            const response = await getUsersList();
            if(response.status == 200){
                setUsers(response.data.results[0])
            }else{
                // do nothing
            }
        }
    })

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container fluid>
                <div className="header-body">
                </div>
                    <div className="bg-white p-4 rounded">
                        <h3 className="mb-4 mt-2 ml-1">Pesquisa</h3>
                        <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div className="col-3" style={{ minWidth: '250px' }} >
                                <Label>Usuário</Label><Select size="lg" className="w-100" placeholder="Selecione um assunto"></Select>
                            </div>
                            <div className="col-3" style={{ minWidth: '250px' }}>
                                <Label>Evento</Label><Select size="lg" className="w-100" placeholder="Selecione um subassunto"></Select>
                            </div>
                            <CustomProvider locale={translateData.find(item => item.key === 'ptBR').value}>
                                <div className="col-3" style={{ minWidth: '200px', maxWidth: '300px' }}> 
                                    <Label style={{ width: '200px' }}>Data início</Label>
                                    <DatePicker oneTap value={startDate} onChange={setStartDate}
                                        shouldDisableDate={(date) => {
                                            if (!endDate) return false;
                                            return date > endDate;
                                        }}
                                    size="lg" format="dd/MM/yyyy" placeholder="Data de início"/>
                                </div>
                                <div className="col-3" style={{ minWidth: '250px' }}>
                                    <Label className="w-100">Data fim</Label>
                                    <DatePicker oneTap value={endDate} onChange={setEndDate}
                                        shouldDisableDate={(date) => {
                                            if (!startDate) return false;
                                            return date < startDate;
                                        }}
                                    size="lg" format="dd/MM/yyyy" placeholder="Data de fim"/>
                                </div>
                                </CustomProvider>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <Button color="warning" size="md" className='mr-3' ><i class="fa-solid fa-trash mr-2"></i>Limpar</Button>
                            <Button color="primary" size="md" ><i class="fa-solid fa-magnifying-glass mr-2" ></i>Pesquisar</Button>
                        </div>
                    </div>
                </Container>
            </div>

            <Container className="mt--7" style={{ minHeight: '70vh' }} fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0 d-flex flex-row align-items-center justify-content-between">
                                <h3 className="mb-0">Logs</h3>
                                <div>
                                    
                                </div>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Usuário</th>
                                        <th scope="col">Evento</th>
                                        <th scope="col">Data</th>
                                        <th scope="col">Hora</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Nome do usuário</td>
                                        <td>Evento</td>
                                        <td>Data</td>
                                        <td>Hora</td>
                                    </tr>

                                </tbody>

                            </Table>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}