import '../../assets/css/rsuite.css';

//table
import {
    Card,
    CardFooter,
    CardHeader,
    Container,
    Label,
    Media,
    Row,
    Table,
} from "reactstrap";
import { CustomLoadingOverlay, CustomNoRowsOverlay, LostConnectionOverlay } from '../../components/Utils/nodata.js'
import { Input, Message, Modal, useToaster, Button, } from "rsuite";
//header
import React, { useEffect, useState } from "react";
import { deleteCategory, getCategoryList, getCategoryParams, saveCategory, updateCategory } from "services/categoryService";

import Drawer from 'rsuite/Drawer';
import ProtectedRoute from "auth/protectedRoute.js";

import { Pagination } from 'rsuite';

import { MessageBox, messagesContent } from "../../components/Utils/MessageBox"

const messageError = MessageBox("error", "Erro", messagesContent.category.messageError)
const messageUpdateError = MessageBox("error", "Erro", messagesContent.category.messageUpdateError)
const messageUpdateSuccess = MessageBox("success", "Sucesso", messagesContent.category.messageUpdateSuccess)
const errorDeleteAlert = MessageBox("error", "Erro", messagesContent.category.errorDeleteAlert)
const successDeleteAlert = MessageBox("success", "Sucesso", messagesContent.category.successDeleteAlert)
const messageSuccess = MessageBox("success", "Sucesso", messagesContent.category.messageSuccess)
const alertFields = MessageBox("warning", "Atenção", messagesContent.common.alertFields)

export default function Category(){

    const toaster = useToaster();

    const [category, setCategory] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [lostConnection, setLostConnection] = useState(false);

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);

    const [name, setName] = useState('')
    const [nomeNucleoSearch, setNomeNucleoSearch] = useState('')

    const [allFieldsFill, setAllFieldsFill] = useState([false]);


    //refatorar
    const [categoryList, setCategoryList] = useState([]);

    const [atualItem, setAtualItem] = useState();

    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);  
    const handleModalClose = () => setModalOpen(false);

    //pagination states
    const limitOptions = [25, 50, 100];
    const [layout, setLayout] = React.useState(['total', '-', 'limit', '|', 'pager', 'skip']);
    const [total, setTotal] = React.useState(1);
    const [activePage, setActivePage] = React.useState(1);
    const [limit, setLimit] = React.useState(25);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === "1") {
                event.preventDefault();
                setOpen(true);
            }else if(event.ctrlKey && event.key === "2"){
                event.preventDefault();
                //DONOTHING 
            }else if(event.ctrlKey && event.key === "3"){
                event.preventDefault();
                if(open){
                    handleSubmit();
                }else if(openEdit){
                    handleUpdate();
                }
            }else if(event.ctrlKey && event.key === "4"){
                event.preventDefault();
                setOpenEdit(false);
                setOpen(false); 
            }else if(event.ctrlKey && event.key === "0"){
                event.preventDefault();
                handleClear();
            }else if(event.ctrlKey && event.key === "7"){
                event.preventDefault();
                handleClearSearch();
            }else if(event.ctrlKey && event.key === "8"){
                event.preventDefault();
                searchParams();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    });
    
    

    //busca lista de nucleos
    useEffect(() => {
        const fetchData = async () => {
          try {
            const resultado = await getCategoryList(activePage, limit, true);
            if(resultado.status != 200){
                setIsLoading(false);
                setLostConnection(true);
            }else{
                setCategory(resultado.data.results);
                setTotal(resultado.data.count);
                setIsLoading(false);
            }
          } catch (error) {
            console.error('Erro ao buscar dados assíncronos:', error);
          }
        };
        fetchData();

    },[]); 


    //validator
    useEffect(() => {
        try{
            if(name != '' && name != null && name != undefined){
                document.getElementById('input-name').style.borderColor = 'green';
                setAllFieldsFill(() => {allFieldsFill[0] = true; return allFieldsFill;});
            }else{
                document.getElementById('input-name').style.borderColor = 'red';
                setAllFieldsFill(() => {allFieldsFill[0] = false; return allFieldsFill;});
            }

        }catch(error){
            
        }


    }, [name])


    //envia nucleo
    const handleSubmit = () => {

        if(allFieldsFill.includes(false)){
            toaster.push(alertFields, {duration: 5000 })
            return;
        }
        
        const nucleoData = {name: name}
        const saveData = async () => {
            try {
              const resultado = await saveCategory(nucleoData);
              console.log(resultado.data)
              if(resultado.status != 201){
                toaster.push(messageError, {duration: 5000 })
              }else{
                toaster.push(messageSuccess, {duration: 5000 })
                setCategory([resultado.data, ...category]);
                setOpen(false);
                handleClear();
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
          };

        saveData();
    }


    //deleta nucleo
    const handleDelete = () => {

        const handleDelete = async () => {
            try {
              const resultado = await deleteCategory(atualItem);
              console.log(resultado)
              if(resultado.status != 204){
                toaster.push(errorDeleteAlert, {duration: 5000 })
              }else{
                toaster.push(successDeleteAlert, {duration: 5000 })
                setCategory(category.filter(nucleo => nucleo.id !== atualItem))
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
          };

          handleDelete();
          handleModalClose();
    }	

    //limpa campos
    function handleClear() {
        
        setName('');
        setAllFieldsFill([false]);
    }

    //limpa campos do search
    function handleClearSearch() {
        setNomeNucleoSearch('');
    }

    // fecha o modal
    const handleEditModalClose = () => {
        
        setOpenEdit(false);
        handleClear();
    }

    //abre modal de edição
    const handleClickEdit = (nucleoname) => {
        setName(nucleoname)
        setOpenEdit(true);
    }

    //atualiza dados
    const handleUpdate = () => {
        const nucleoUpdate = { name: name}
        const saveData = async () => {
            try {
              const resultado = await updateCategory(atualItem, nucleoUpdate);
              console.log(resultado.data)
              if(resultado.status != 200){
                toaster.push(messageUpdateError, {duration: 5000 })
              }else{
                toaster.push(messageUpdateSuccess, {duration: 5000 })
                setCategory(category.map(nucleo => nucleo.id === atualItem ? resultado.data : nucleo))
                setOpenEdit(false);
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
          };

          saveData();
        
    }

    //busca com parametros
    const searchParams = () => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
              const resultado = await getCategoryParams(nomeNucleoSearch, activePage, limit, true);
              if(resultado.status != 200){
                setCategory([]);
                setIsLoading(false);
              }else{
                setCategory(resultado.data.results);
                setTotal(resultado.data.count);
                setIsLoading(false);
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
        }
        fetchData();
    }

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container fluid>
                <div className="header-body">
                </div>
                <div className="bg-white p-4 rounded">
                    <h3 className="mb-4 mt-2 ml-1">Núcleo</h3>
                    <div className="row">
                        <div className="col-6">
                            <Label>Nome</Label><Input placeholder="Digite o nome do núcleo" value={nomeNucleoSearch} onChange={setNomeNucleoSearch}></Input>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <Button color="warning" className='mr-3' size="md" onClick={handleClearSearch}><i class="fa-solid fa-trash mr-2"></i>Limpar</Button>
                        <Button color="primary" size="md" onClick={searchParams}><i class="fa-solid fa-magnifying-glass mr-2" ></i>Pesquisar</Button>
                    </div>
                </div>
                </Container>
                
            </div>
            
            <Container className="mt--7" style={{ minHeight: '70vh' }} fluid>
        {/* Table */}
            <Row>
                <div className="col">
                    <Card className="shadow">
                    <CardHeader className="border-0 d-flex flex-row align-items-center justify-content-between">
                        <h3 className="mb-0">Núcleo</h3>
                        <ProtectedRoute permissionsAccepted={['add_category']} mode={'component'}>
                            <div>
                                <Button color="primary" size="md" onClick={() => setOpen(true)} ><i className="fas fa-plus mr-2 "  />Novo núcleo</Button>
                            </div>
                        </ProtectedRoute>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Nome </th>
                            <ProtectedRoute permissionsAccepted={['change_category', 'delete_category']} mode={'component'}>
                                <th scope="col">Ações </th>
                            </ProtectedRoute>
                        </tr>
                        </thead>
                        <tbody>
                            {
                            !isLoading && !lostConnection && category.length > 0 &&
                                category.map((nucleo) => {
                                    
                                    return (<tr key={nucleo.id}>
                                        <th scope="row">
                                            <Media>
                                                <span className="mb-0 text-sm">
                                                    {nucleo.name}
                                                </span>
                                            </Media>
                                        </th>
                                        <ProtectedRoute permissionsAccepted={['change_category', 'delete_category']} mode={'component'}>
                                            <td className="align-right">
                                                <ProtectedRoute permissionsAccepted={['change_category']} mode={'component'}>
                                                    <Button className='mr-2'  onClick={() => {
                                                        setAtualItem(nucleo.id);
                                                        handleClickEdit(nucleo.name)}}>
                                                        <i class="fa-solid fa-pen"></i>
                                                    </Button>
                                                </ProtectedRoute>
                                                <ProtectedRoute permissionsAccepted={['delete_category']} mode={'component'}>
                                                    <Button style={{ backgroundColor: "#f87171", color: 'white' }}  onClick={() => {
                                                                setAtualItem(nucleo.id);
                                                                setModalOpen(true);}}>
                                                        <i class="fa-solid fa-trash"></i>
                                                    </Button>
                                                </ProtectedRoute>
                                            </td>
                                        </ProtectedRoute>
                                    </tr>)
                                })
                            }
                        </tbody>
                    </Table>
                    
                    {isLoading &&<div className="mb-4 mt-4 w-100 d-flex align-items-center justify-content-center flex-column" >
                        {CustomLoadingOverlay()}
                    </div>}
                    {!isLoading && lostConnection &&
                        <div className="mb-4 mt-4 w-100 d-flex align-items-center justify-content-center flex-column" >
                            { LostConnectionOverlay()}
                        </div>
                    }
                    {!isLoading && !lostConnection && category.length == 0 &&
                        <div className="mb-4 mt-4 w-100 d-flex align-items-center justify-content-center flex-column" >
                        {CustomNoRowsOverlay()}
                         </div>
                    }
                    <CardFooter className="py-4">
                        <nav aria-label="...">
                        <Pagination
                                layout={layout}
                                size={'xs'}
                                prev={true}
                                next={true}
                                first={true}
                                last={true}
                                ellipsis={true}
                                boundaryLinks={true}
                                total={total}
                                limit={limit}
                                limitOptions={limitOptions}
                                maxButtons={4}
                                activePage={activePage}
                                onChangePage={setActivePage}
                                onChangeLimit={setLimit}
                            />
                        </nav>
                    </CardFooter>
                    </Card>
                </div>
            </Row>
            {/* adicionar nucleo */}
            <ProtectedRoute permissionsAccepted={['add_category']} mode={'component'}>
                <Drawer backdrop="static" open={open} onClose={() => setOpen(false)}>
                    <Drawer.Header className="pt-4">
                        <h4>Cadastro de Núcleo</h4>
                    </Drawer.Header>
                    <Drawer.Body>
                            <Row className="mb-4">
                                <h4>Nome</h4>
                                <Input placeholder="Digite o nome do núcleo" id="input-name" value={name} onChange={setName}></Input>
                            </Row>
                            <Row>
                                <Button onClick={handleClear} style={{ backgroundColor: '#fb923c', color: 'white' }} color="warning" className="position-absolute bottom-0 end-0 mb-4">Limpar campos</Button>
                                <Button onClick={handleSubmit} color="primary" className="position-absolute bottom-0 end-0 right-0 mr-5 mb-4">Salvar</Button>
                            </Row>
                    </Drawer.Body>
                </Drawer>
            </ProtectedRoute>

            {/* editar processo */}
            <ProtectedRoute permissionsAccepted={['change_category']} mode={'component'}>
                <Drawer backdrop="static" open={openEdit} onClose={handleEditModalClose}>
                    <Drawer.Header className="pt-4">
                        <h4>Edição de Núcleo</h4>
                    </Drawer.Header>
                    <Drawer.Body>
                            <Row className="mb-4">
                                <h4>Nome</h4>
                                <Input placeholder="Digite uma observação" id="input-name" value={name} onChange={setName}></Input>
                            </Row>
                            <Row>
                                <Button onClick={handleUpdate} color="primary" className="position-absolute bottom-0 end-0 right-0 mr-5 mb-4">Atualizar</Button>
                            </Row>
                    </Drawer.Body>
                </Drawer>
            </ProtectedRoute>

            <ProtectedRoute permissionsAccepted={['delete_category']} mode={'component'}>
                <Modal open={modalOpen} onClose={handleModalClose}>
                    <Modal.Header>
                    <Modal.Title>Deletar Núcleo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Deseja realmente deletar o Núcleo? Essa ação é irreversível.
                    </Modal.Body>
                    <Modal.Footer>
                    <Button size="sm" onClick={handleModalClose} appearance="subtle">
                        Cancelar
                    </Button>
                    <Button size="sm" style={{ backgroundColor: '#f87171' }} onClick={handleDelete} appearance="primary">
                        Confirmar
                    </Button>
                    </Modal.Footer>
                </Modal>
            </ProtectedRoute>
        </Container>
        </>
    )
}