import { Button, Col, Grid, Modal, Row } from 'rsuite';
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import AdminFooter from "components/Footers/AdminFooter.js";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
// reactstrap components
import { Container } from "reactstrap";


import Greetings from "layouts/Greetings";
/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import RolesSync from "auth/rolesSync";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import Profile from 'views/Profile/Profile';
import ProtectedRoute from 'auth/protectedRoute';
import Notifications from 'views/notifications/notifications';
import Uniqprocess from 'views/processes/uniqprocess';
import UniqUser from 'views/admin/uniquser';
import { getRolesDescript } from 'auth/jwtUtils';

//logo lineu
import { ReactComponent as LogoIcon } from 'assets/img/brand/lineu-logo.svg'

const Admin = (props) => {
  // Sidebar state
  const [sidebarCollapseActive, setSidebarCollapseActive] = useState(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const mainContent = React.useRef(null);
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {setOpen(true)};
  const handleClose = () => setOpen(false);


  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    try{
      mainContent.current.scrollTop = 0;
    }catch(err){
      
    }
  }, [location]);

  //key control + a = to route process
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "a") {
        event.preventDefault();
        window.location.href = "/dashboard";
      }else if(event.ctrlKey && event.key === "s"){
        event.preventDefault();
        window.location.href = "/processo";
      }else if(event.ctrlKey && event.key === "d"){
        event.preventDefault();
        window.location.href = "/nucleo";
      }else if(event.ctrlKey && event.key === "f"){
        event.preventDefault();
        window.location.href = "/assunto";
      }else if(event.ctrlKey && event.key === "g"){
        event.preventDefault();
        window.location.href = "/subassunto";
      }else if(event.ctrlKey && event.key === "k"){
        event.preventDefault();
        window.location.href = "/painel-de-controle/grupos";
      }else if(event.ctrlKey && event.key === "l"){
        event.preventDefault();
        window.location.href = "/painel-de-controle/usuarios";
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  },);

  //open fast modal with ctrl + shift + ? key
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key === "?") {
        event.preventDefault();
        handleOpen();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  },);


  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if(!prop.submenu){
        if (prop.layout !== null) {
          return (
            <Route path={prop.layout +  prop.path} element={prop.component} key={key} exact/>
          );
        } else {
          return null;
        }
      }else{
        return prop.submenuItems.map((child, key) => {
          if (prop.layout !== null) {
            return (
              <Route path={prop.layout + child.path} element={child.component} key={key} exact/>
            );
          } else {
            return null;
          }
        });
      }
    
    }

    );
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if(routes[i].submenu > 0){
        for (let j = 0; j < routes[i].submenuItems.length; j++) {
          if("/" + routes[i].layout + routes[i].submenuItems[j].path === path){
            return routes[i].name + " / " + routes[i].submenuItems[j].name;
          }
        }
      }else{
        if(routes[i].layout + routes[i].path === path){
          return routes[i].name;
        }
      }
    }
    return path.replace('/', '');
  };

  return (

     <RolesSync>

       { getRolesDescript().length == 0 ? <Greetings /> :

        <>
        <Sidebar
          {...props}
          routes={routes}
          logo={{
            innerLink: "/dashboard",
            imgSrc: require("../assets/img/brand/lineu-logo.jpeg"),
            imgAlt: "...",
          }}
          collapsed={sidebarCollapsed}
          setCollapsed={setSidebarCollapsed}
          collapseActive={sidebarCollapseActive}
          setCollapseActive={setSidebarCollapseActive}
        />
        <div className="main-content" style={{ marginLeft: sidebarCollapsed ? "80px" : null }} ref={mainContent}>
          <AdminNavbar
            {...props}
            brandText={getBrandText(location.pathname)}
            sidebarCollapsed={sidebarCollapsed}
          />
          <Routes>
            {getRoutes(routes)}
            
            
            <Route path="*" element={<Navigate to="dashboard" replace />} />
            
            
            <Route path="/perfil" element={
              <ProtectedRoute permissionsAccepted={[]} mode={'route'}>
                <Profile />
              </ProtectedRoute>
            } exact/>
            <Route path="/notificacoes" element={
              <ProtectedRoute permissionsAccepted={[]} mode={'route'}>
                <Notifications />
              </ProtectedRoute>
            } exact/>

            <Route path='/gerenciar/processo/:id' element={
              <ProtectedRoute permissionsAccepted={['view_process', 'view_all_process']} mode={'route'}>
                <Uniqprocess />
              </ProtectedRoute>
            } exact/>

            <Route path='/painel-de-controle/usuarios/:id' element={
              <ProtectedRoute permissionsAccepted={['view_user']} mode={'route'}>
                <UniqUser />
              </ProtectedRoute>
            } exact/>
          </Routes>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>

        <Modal open={open} onClose={handleClose} size={'calc(100% - 300px)'}>
          <Modal.Header>
            <Modal.Title>Atalhos de teclado</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflow: 'hidden' }}>
            <Row className="show-grid">
              <Col lg={10} xs={3}>
                <h4>Atalhos globais</h4>
                <p><strong>Ctrl + a</strong>: Dashboard</p>
                <p><strong>Ctrl + s</strong>: Processo</p>
                <p><strong>Ctrl + d</strong>: Núcleo</p>
                <p><strong>Ctrl + f</strong>: Assunto</p>
                <p><strong>Ctrl + g</strong>: Subassunto</p>
                <p><strong>Ctrl + k</strong>: Grupos</p>
                <p><strong>Ctrl + l</strong>: Usuários</p>
                <p><strong>TAB</strong>: Próximo campo</p>
              </Col>
              <Col lg={10} xs={3}>
                <h4>Demais atalhos</h4>
                <p><strong>Ctrl + 1</strong>: Novo processo/núcleo/assunto/subassunto</p>
                <p><strong>Ctrl + 2</strong>: Novo processo em lote</p>
                <p><strong>Ctrl + 3</strong>: Salvar processo/núcleo/assunto/subassunto</p>
                <p><strong>Ctrl + 4</strong>: Fechar processo/núcleo/assunto/subassunto</p>
                <p><strong>Ctrl + 0</strong>: Limpar campos do processo/núcleo/assunto/subassunto</p>
                <p><strong>Ctrl + 7</strong>: Pesquisar</p>
                <p><strong>Ctrl + 8</strong>: Limpar campos da pesquisa</p>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
        </>}
      </RolesSync>
  
    
  );
};

export default Admin;
