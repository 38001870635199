import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Divider, Panel, Placeholder, Toggle, Checkbox, CheckboxGroup } from 'rsuite';
import { Message, Modal, useToaster, Button } from "rsuite";
import React, { useEffect, useState } from 'react';
import { createGroup, deleteGroup, getGroups, updateGroup } from "services/groupsService";

// import { ColorPicker } from 'primereact/colorpicker';
import { CustomLoadingOverlay } from "components/Utils/nodata";
import ProtectedRoute from "auth/protectedRoute";
import { MessageBox, messagesContent } from "../../components/Utils/MessageBox"
import { getPermissionsByContentType } from "services/permissionsService";

import './groups.css'

const messageDeleteError = MessageBox("error", "Erro", messagesContent.groups.messageDeleteError)
const messageDeleteSuccess = MessageBox("success", "Sucesso", messagesContent.groups.messageDeleteSuccess)
const messageUpdateError = MessageBox("error", "Erro", messagesContent.groups.messageUpdateError)
const messageUpdateSuccess = MessageBox("success", "Sucesso", messagesContent.groups.messageUpdateSuccess)
const alertFields = MessageBox("warning", "Atenção", messagesContent.common.alertFields)

const CardGroup = props => {

  const toaster = useToaster();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // const [color, setColor] = useState(props.color);

  const [nameGroup, setNameGroup] = useState(props.name);

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => { setModalOpen(false) }

  // valores dos checkbox
  const [valueprocess, setValueProcess] = useState([]);
  const [valueCategory, setValueCategory] = useState([]);
  const [valueSubject, setValueSubject] = useState([]);
  const [valueSubSubject, setValueSubSubject] = useState([]);
  const [valueGroup, setValueGroup] = useState([]);
  const [valueUser, setValueUser] = useState([]);
  const [valueLog, setValueLog] = useState([]);

  // handle de mudancas
  const handleAllProcess = (value, checked) => setValueProcess(checked ? permissions['process'].map(item => item.codename) : []);
  const handleChangeProcess = (value) => setValueProcess(value);

  const handleAllCategory = (value, checked) => setValueCategory(checked ? permissions['category'].map(item => item.codename) : []);
  const handleChangeCategory = (value) => setValueCategory(value);

  const handleAllSubject = (value, checked) => setValueSubject(checked ? permissions['subject'].map(item => item.codename) : []);
  const handleChangeSubject = (value) => setValueSubject(value);

  const handleAllSubSubject = (value, checked) => setValueSubSubject(checked ? permissions['subsubject'].map(item => item.codename) : []);
  const handleChangeSubSubject = (value) => setValueSubSubject(value);

  const handleAllGroup = (value, checked) => setValueGroup(checked ? permissions['groups'].map(item => item.codename) : []);
  const handleChangeGroup = (value) => setValueGroup(value);

  const handleAllUser = (value, checked) => setValueUser(checked ? permissions['user'].map(item => item.codename) : []);
  const handleChangeUser = (value) => setValueUser(value);

  const handleAllLog = (value, checked) => setValueLog(checked ? permissions['system'].map(item => item.codename) : []);
  const handleChangeLog = (value) => setValueLog(value);


  const initialPermitions = () => {
    setValueProcess(() => {
      let p  = props.permissoes;
      // return itens include in permissions['process']
      return p.filter(item => permissions['process'].map(x => x.codename).includes(item.codename)).map(item => item.codename);  
    })

    setValueCategory(() => {
      let p  = props.permissoes;
      return p.filter(item => permissions['category'].map(x => x.codename).includes(item.codename)).map(item => item.codename);  
    })

    setValueSubject(() => {
      let p  = props.permissoes;
      return p.filter(item => permissions['subject'].map(x => x.codename).includes(item.codename)).map(item => item.codename);  
    })

    setValueSubSubject(() => {
      let p  = props.permissoes;
      return p.filter(item => permissions['subsubject'].map(x => x.codename).includes(item.codename)).map(item => item.codename);  
    })

    setValueGroup(() => {
      let p  = props.permissoes;
      return p.filter(item => permissions['groups'].map(x => x.codename).includes(item.codename)).map(item => item.codename);  
    })

    setValueUser(() => {
      let p  = props.permissoes;
      return p.filter(item => permissions['user'].map(x => x.codename).includes(item.codename)).map(item => item.codename);  
    })

    setValueLog(() => {
      let p  = props.permissoes;
      return p.filter(item => permissions['system'].map(x => x.codename).includes(item.codename)).map(item => item.codename);  
    })
  }

  // inicializa permissoes do grupo
  useEffect(() => {
    initialPermitions();
  }, [])


  const permissions = {
    "process": [
      { "codename": "add_process", "label": "Adicionar" },
      { "codename": "change_process", "label": "Alterar" },
      { "codename": "classification_process", "label": "Classificar" },
      { "codename": "delete_process", "label": "Excluir" },
      { "codename": "deliver_process", "label": "Distribuir" },
      { "codename": "view_process", "label": "Visualizar distribuídos" },
      { "codename": "view_all_process", "label": "Visualizar Todos" },
      
    ],
    "system": [
      { "codename": "view_logentry", "label": "Visualizar logs" },
      { "codename": "change_changecategoryrequest", "label": "Aprovar solicitações" },
    ],
    "groups": [
      { "codename": "add_group", "label": "Adicionar" },
      { "codename": "change_group", "label": "Alterar" },
      { "codename": "delete_group", "label": "Excluir" },
      { "codename": "view_group", "label": "Visualizar" }
    ],
    "user": [
      { "codename": "change_user", "label": "Alterar" },
      { "codename": "delete_user", "label": "Desabilitar" },
      { "codename": "view_user", "label": "Visualizar" }
    ],
    "subject": [
      { "codename": "add_subject", "label": "Adicionar" },
      { "codename": "change_subject", "label": "Alterar" },
      { "codename": "delete_subject", "label": "Excluir" },
      { "codename": "view_subject", "label": "Visualizar" }
    ],
    "subsubject": [
      { "codename": "add_subsubject", "label": "Adicionar" },
      { "codename": "change_subsubject", "label": "Alterar" },
      { "codename": "delete_subsubject", "label": "Excluir" },
      { "codename": "view_subsubject", "label": "Visualizar" }
    ],
    "category": [
      { "codename": "add_category", "label": "Adicionar" },
      { "codename": "change_category", "label": "Alterar" },
      { "codename": "delete_category", "label": "Excluir" },
      { "codename": "view_category", "label": "Visualizar" }
    ]
  }

  // reinicializa permissoes do grupo
  useEffect(() => {
    if(!(isExpanded && isEditing)){
      initialPermitions();
    }
  }, [isExpanded, isEditing])

  // expande o card para visualização
  const handleExpandview = () => {
    if(isExpanded && isEditing){
      initialPermitions();
      setIsEditing(false)
    }else if(!isExpanded){
      setIsExpanded(!isExpanded)
      setIsEditing(false)
    }else if(isExpanded && !isEditing){
      setIsExpanded(!isExpanded)
    }
  }

  // expande o card para edição
  const handleExpandEdit = () => {
    if(isExpanded && !isEditing){
      initialPermitions();
      setIsEditing(!isEditing)
    }else if(!isExpanded){
      setIsExpanded(!isExpanded)
      setIsEditing(true)
    }else if(isExpanded && isEditing){
      setIsExpanded(!isExpanded)
      setIsEditing(false)
    }
  }

  // marca todos os checkbox
  const handleCheckAll = () => {
    handleAllProcess(null, true)
    handleAllCategory(null, true)
    handleAllSubject(null, true)
    handleAllSubSubject(null, true)
    handleAllGroup(null, true)
    handleAllUser(null, true)
    handleAllLog(null, true)
  }

  // desmarca todos os checkbox
  const handleUncheckAll = () => {
    setValueProcess([])
    setValueCategory([]);
    setValueSubject([]);
    setValueSubSubject([]);
    setValueGroup([]);
    setValueUser([]);
    setValueLog([]);
  }

  // deleta o grupo
  const handleDelete = () => {
    const doDelelete = async () => {
      try{
        const response = await deleteGroup(props.groupId);
        if(response.status === 204){
          setModalOpen(false);
          toaster.push(messageDeleteSuccess, {duration: 5000});
          window.location.reload();
        }else{
          console.log(response)
          toaster.push(messageDeleteError, {duration: 5000});
        }
      }catch(error){
        toaster.push(messageDeleteError, {duration: 5000});
        console.log(error)
      }
    }

    doDelelete();
  }

  // atualiza o grupo
  const handleUpdate = () => {

    if(nameGroup === "" || nameGroup === undefined || nameGroup === null){
      toaster.push(alertFields, {duration: 5000});
      return;
    }

    let onlyCodename = []

    onlyCodename = onlyCodename.concat(valueprocess, valueCategory, valueSubject, valueSubSubject, valueGroup, valueUser, valueLog);

    const doUpdate = async () => {
      try{
        const response = await updateGroup( props.groupId,{name: nameGroup, color: '000000', permissions_codes: onlyCodename });
        if(response.status === 200){
          toaster.push(messageUpdateSuccess, {duration: 5000});
          window.location.reload();
        }else{
          console.log(response)
          toaster.push(messageUpdateError, {duration: 5000});
        }
      }catch(error){
        toaster.push(messageUpdateError, {duration: 5000});
        console.log(error)
      }
    }

    doUpdate();
  }

  // limita visualizar distribuidos e todos ao mesmo tempo (not and)
  useEffect(() => {
    if(valueprocess.includes('view_process') && valueprocess.includes('view_all_process')){
      // find last between view_process and view_all_process and remove the first
      let indexViewProcess = valueprocess.indexOf('view_process');
      let indexViewAllProcess = valueprocess.indexOf('view_all_process');
      if(indexViewProcess < indexViewAllProcess){
        setValueProcess(valueprocess.filter(item => item !== 'view_process'))
      }else{
        setValueProcess(valueprocess.filter(item => item !== 'view_all_process'))
      }
    }
  }, [valueprocess])

  return (
    <Panel {...props} bordered header={
      <div className="d-flex" style={{ alignItems: 'center' }}>

        {!isExpanded ? 
        <>
            {/* <ColorPicker value={props.color} style={{ marginRight: 5 }}  /> */}
            <h3 style={{ textAlign: 'center'}}>
              {nameGroup}
            </h3>
          </>
          :
          <>
          {/* <ColorPicker value={color} onChange={(e) => setColor(e.value)} style={{ marginRight: 5 }}  /> */}
          {isEditing ? <Input value={nameGroup} onChange={(e) => setNameGroup(e.target.value)} style={{ maxWidth: '400px' }} />
          : <h3 style={{ textAlign: 'center'}}>
              {nameGroup}
            </h3>
        }
        </>
      }
        <div style={{ marginLeft: 'auto' }}> 
          <Button size={ isExpanded && !isEditing ? "lg" : "md" } color="primary" className="mr-2" onClick={handleExpandview}><i class="fa-solid fa-eye"></i></Button>
          <ProtectedRoute permissionsAccepted={["change_group"]} mode="component">
            <Button size={ isExpanded && isEditing ? "lg" : "md" } className="mr-2" onClick={handleExpandEdit}><i class="fa-solid fa-pen"></i></Button>
          </ProtectedRoute>
          <ProtectedRoute permissionsAccepted={["delete_group"]} mode="component">
            <Button size="md" color="danger" onClick={() => {setModalOpen(true)}} style={{ backgroundColor: '#f87171', color: 'white' }}><i class="fa-solid fa-trash"></i></Button>
          </ProtectedRoute>
        </div> 
      </div>
    } style={{ width: isExpanded ? '80vw' : '400px', transition: 'width 0.3s ease-in-out, height 0.3s ease-in-out', marginRight: '2%', marginBottom: '2%' }}>
      {
        isExpanded &&
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 15 }}>
          <div className="col">
            <Checkbox
              indeterminate={valueprocess.length > 0 && valueprocess.length < permissions['process'].length}
              checked={valueprocess.length === permissions['process'].length}
              onChange={handleAllProcess}
              disabled={!isEditing}
            >
              <span className="formal-text">Processo</span>
            </Checkbox>
            <CheckboxGroup
              name="checkboxprocess"
              value={valueprocess}
              onChange={handleChangeProcess}
              style={{ marginLeft: 36 }}
              disabled={!isEditing}
            >
              {permissions['process'].map((item) => (
                <Checkbox key={item.codename} value={item.codename}>
                  <span className="formal-text"> {item.label}</span>
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>

          <div className="col">
            <Checkbox
              indeterminate={valueCategory.length > 0 && valueCategory.length < permissions['category'].length}
              checked={valueCategory.length === permissions['category'].length}
              onChange={handleAllCategory}
              disabled={!isEditing}
            >
              <span className="formal-text">Núcleo</span>
            </Checkbox>
            <CheckboxGroup
              name="checkboxcategory"
              value={valueCategory}
              onChange={handleChangeCategory}
              style={{ marginLeft: 36 }}
              disabled={!isEditing}
            >
              {permissions['category'].map((item) => (
                <Checkbox key={item.codename} value={item.codename}>
                  <span className="formal-text">{item.label}</span>
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>

          <div className="col">
            <Checkbox
              indeterminate={valueSubject.length > 0 && valueSubject.length < permissions['subject'].length}
              checked={valueSubject.length === permissions['subject'].length}
              onChange={handleAllSubject}
              disabled={!isEditing}
            >
              <span className="formal-text">Assunto</span>
            </Checkbox>
            <CheckboxGroup
              name="checkboxsubject"
              value={valueSubject}
              onChange={handleChangeSubject}
              style={{ marginLeft: 36 }}
              disabled={!isEditing}
            >
              {permissions['subject'].map((item) => (
                <Checkbox key={item.codename} value={item.codename}>
                  <span className="formal-text">{item.label}</span>
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>

          <div className="col">
            <Checkbox
              indeterminate={valueSubSubject.length > 0 && valueSubSubject.length < permissions['subsubject'].length}
              checked={valueSubSubject.length === permissions['subsubject'].length}
              onChange={handleAllSubSubject}
              disabled={!isEditing}
            >
              <span className="formal-text">SubAssunto</span>
            </Checkbox>
            <CheckboxGroup
              name="checkboxsubsubject"
              value={valueSubSubject}
              onChange={handleChangeSubSubject}
              style={{ marginLeft: 36 }}
              disabled={!isEditing}
            >
              {permissions['subsubject'].map((item) => (
                <Checkbox key={item.codename} value={item.codename}>
                  <span className="formal-text">{item.label}</span>
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>

          <div className="col">
            <Checkbox
              indeterminate={valueGroup.length > 0 && valueGroup.length < permissions['groups'].length}
              checked={valueGroup.length === permissions['groups'].length}
              onChange={handleAllGroup}
              disabled={!isEditing}
            >
              <span className="formal-text">Grupo</span>
            </Checkbox>
            <CheckboxGroup
              name="checkboxgroup"
              value={valueGroup}
              onChange={handleChangeGroup}
              style={{ marginLeft: 36 }}
              disabled={!isEditing}
            >
              {permissions['groups'].map((item) => (
                <Checkbox key={item.codename} value={item.codename}>
                  <span className="formal-text">{item.label}</span>
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>

          <div className="col">
            <Checkbox
              indeterminate={valueUser.length > 0 && valueUser.length < permissions['user'].length}
              checked={valueUser.length === permissions['user'].length}
              onChange={handleAllUser}
              disabled={!isEditing}
            >
              <span className="formal-text">Usuário</span>
            </Checkbox>
            <CheckboxGroup
              name="checkboxuser"
              value={valueUser}
              onChange={handleChangeUser}
              style={{ marginLeft: 36 }}
              disabled={!isEditing}
            >
              {permissions['user'].map((item) => (
                <Checkbox key={item.codename} value={item.codename}>
                  <span className="formal-text">{item.label}</span>
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>

          <div className="col">
            <Checkbox
              indeterminate={valueLog.length > 0 && valueLog.length < permissions['system'].length}
              checked={valueLog.length === permissions['system'].length}
              onChange={handleAllLog}
              disabled={!isEditing}
            >
              <span className="formal-text">Sistema</span>
            </Checkbox>
            <CheckboxGroup
              name="checkboxlog"
              value={valueLog}
              onChange={handleChangeLog}
              style={{ marginLeft: 36 }}
              disabled={!isEditing}
            >
              {permissions['system'].map((item) => (
                <Checkbox key={item.codename} value={item.codename}>
                  <span className="formal-text">{item.label}</span>
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>

          </div>
          {isEditing &&
            <ProtectedRoute permissionsAccepted={["change_group"]} mode="component">
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 40px 30px 40px' }}>
                <div>
                  <Button size="sm" className="mr-3" onClick={handleCheckAll}>Marcar todos</Button>
                  <Button size="sm" onClick={handleUncheckAll}>Desmarcar todos</Button>
                </div>
                <div>
                  <Button size="sm" color="primary" onClick={handleUpdate} >Salvar</Button>
                </div>
              </div>
            </ProtectedRoute>
          }
        </div>
        
      }
      <ProtectedRoute permissionsAccepted={["delete_group"]} mode="component">
            <Modal open={modalOpen} onClose={handleModalClose}>
                <Modal.Header>
                <Modal.Title>Deletar grupo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja realmente deletar o grupo {props.name}? Essa ação é irreversível.
                </Modal.Body>
                <Modal.Footer>
                <Button size="sm" onClick={handleModalClose} appearance="subtle">
                    Cancelar
                </Button>
                <Button size="sm" color="danger" onClick={handleDelete} appearance="primary">
                    Confirmar
                </Button>
                </Modal.Footer>
            </Modal>
      </ProtectedRoute>
    </Panel>
  )
};

const messageCreateError = MessageBox("error", "Erro", messagesContent.groups.messageCreateError)
const messageCreateSuccess = MessageBox("success", "Sucesso", messagesContent.groups.messageCreateSuccess)

export default function Groups() {

  const toaster = useToaster();

  const [isLoading, setIsLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
      const fetchData = async () => {
          try{
            setIsLoading(true);
            const resultado = await getGroups();
            if(resultado.status === 200){
              setGroups(resultado.data.results)
              setIsLoading(false);
            }else{
              console.log(resultado)
            }
          }catch(error){
            console.log(error)
          }
      }

      fetchData();
  }, [])

  const newGroup = () => {
    setIsCreating(true);
    const saveGroup = async () => {
      try{
        const horario = new Date();
        let hms = horario.getHours() + "" + horario.getMinutes() + "" + horario.getSeconds();

        const response = await createGroup({ name: 'Novo grupo'+hms, color: '000000', permissions: [] });
        console.log(response)
        if(response.status === 201){
          const novoGrupo = response.data;
          setGroups([novoGrupo, ...groups])
          setIsCreating(false);
          toaster.push(messageCreateSuccess, {duration: 5000});
        }else{
          setIsCreating(false);
          toaster.push(messageCreateError, {duration: 5000});
        }
      }catch(error){
        setIsCreating(false);
        toaster.push(messageCreateError, {duration: 5000});
      }
    
    }

    saveGroup();
  }

  return(
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
          <div className="header-body">
          </div>
          </Container>
          
      </div>
      
      <Container className="mt--7" style={{ minHeight: '70vh' }} fluid>
        <Row>
          <div className="col">
            <Card>
                <CardHeader className="border-0 d-flex flex-row align-items-center justify-content-between">
                    <h3 className="mb-0">Grupos</h3>
                    <div>
                        <ProtectedRoute permissionsAccepted={["add_group"]} mode="component">
                          <Button color="primary" size="md" onClick={newGroup}><i className="fas fa-plus mr-2 "  />Criar grupo</Button>
                        </ProtectedRoute>
                    </div>
                </CardHeader>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    {isCreating && !isLoading && <Panel style={{ marginRight: 5, width: '400px'  }} bordered header="Criando card">
                        <Placeholder.Paragraph />
                      </Panel> }
                      {
                        isLoading ? <div className="mb-4 mt-4 w-100 d-flex align-items-center justify-content-center flex-column" >
                        {CustomLoadingOverlay()}
                    </div> :
                        groups.map((group, index) => {
                          return (
                            <CardGroup key={group.id} groupId={group.id} name={group.name} permissoes={group.permissions} bodyFill>

                            </CardGroup>
                          )
                        })
                      }
                  </div>  
                </CardBody>
            </Card>
          </div>
        </Row>

      </Container>
    </>
  )
}