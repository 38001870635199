import React from "react";
import imgDenied from "../../assets/img/brand/accessdenied2.png";
import { Container } from "reactstrap";
import { NavLink } from "react-router-dom";

export default function DeniedAcess() {
    return (
        <>
            <div className="head bg-gradient-info pt-5 pt-md-8">
                
            </div>
            <Container className="mt--7" fluid>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <img src={imgDenied} style={{ width: '40%' }} alt="Denied" />
                    <h3>Ops! Você não tem permissão para acessar essa página.</h3>
                </div>
            </Container>
        </>
    );
}