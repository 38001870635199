import CryptoJS from 'crypto-js';
import DeniedAcess from "views/pages/denied";
import React from "react";
import { getInfosByToken, isValidToken } from "./jwtUtils";
import { NavLink, Link, redirect, useNavigate } from 'react-router-dom';
import { getUserRoles } from 'services/userService';

const internalCaipora = process.env.REACT_APP_INTERNAL_HOME;

export const destroySession = () => {
  localStorage.removeItem('raccoon');
  window.location.href = internalCaipora;
}

export const destroySessionNoRedirect = () => {
  localStorage.removeItem('raccoon');
}

const ProtectedRoute = ({ children, permissionsAccepted, mode, alternative }) => {

  const navigate = useNavigate();

  const apptoken = process.env.REACT_APP_APPLICATION_TOKEN;

  const raccoon = localStorage.getItem('raccoon');

  if(raccoon != null){
    const key = CryptoJS.SHA256(apptoken).toString()
    const decrypted = CryptoJS.AES.decrypt(raccoon, key);
    let dec = decrypted.toString(CryptoJS.enc.Utf8)
    try{
      const tokenjwt = JSON.parse(dec).jwt;

      if(isValidToken(tokenjwt)){

        if(!JSON.parse(dec).infos.isActive){
          destroySession();
          navigate("/account-error")
        }

        const roles = JSON.parse(dec).roles;

        if(permissionsAccepted.some(r=> roles.includes(r)) || permissionsAccepted.length == 0){
          return children;
        }else{
          
          if(mode == 'route'){
            return <DeniedAcess />
          }
          else if(mode == 'component'){
            if(alternative){
              return alternative;
            }
            return;
          }
        }
      }else{
        window.location.href = internalCaipora
      }
      return children;
    }catch(e){
      window.location.href = internalCaipora
    }
  }else{
    //window.location.href = internalCaipora
  }
};

export default ProtectedRoute;