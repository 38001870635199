import '../../assets/css/rsuite.css';

//table
import {
    Card,
    CardFooter,
    CardHeader,
    Container,
    Input as InputStrap,
    Label,
    Media,
    Row,
    Table,
} from "reactstrap";

import { CustomLoadingOverlay, CustomNoRowsOverlay, LostConnectionOverlay } from '../../components/Utils/nodata'

import { ptBR } from 'rsuite/esm/locales';
import { Input, MaskedInput, Message, Modal, Whisper, useToaster, Tooltip, Button, CustomProvider} from "rsuite";
//header
import React, { useEffect, useState } from "react";
import { cadastrarProcessoLote, deleteProcess, getProcessList, getProcessParams, saveProcess } from "services/processService";
import { getSubSubjectList, getSubSubjectsByIdSubject } from "services/subsubjectService";
import { requestChangeProcessCategory, getRequests } from 'services/requestService';

import { useParams, Navigate, NavLink  } from 'react-router-dom';

import Drawer from 'rsuite/Drawer';
import ProtectedRoute from "auth/protectedRoute";
import Select from 'rsuite/SelectPicker'
import { getCategoryList } from "services/categoryService";
import { getSubjectList } from "services/subjectService";
import { updateProcess } from "services/processService";
import excelicon from '../../assets/img/icons/common/excelicon.png'
import * as XLSX from 'xlsx';
import { Pagination, Loader } from 'rsuite';
import { MessageBox, messagesContent } from "../../components/Utils/MessageBox"
import { getAdditionalInfoDescript, hasRole } from 'auth/jwtUtils';

const messageUpdateError = MessageBox("error", "Erro", messagesContent.process.messageUpdateError)
const messageUpdateSuccess = MessageBox("success", "Sucesso", messagesContent.process.messageUpdateSuccess)
const messageError = MessageBox("error", "Erro", messagesContent.process.messageError)
const errorDeleteAlert = MessageBox("error", "Erro", messagesContent.process.errorDeleteAlert)
const successDeleteAlert = MessageBox("success", "Sucesso", messagesContent.process.successDeleteAlert)
const messageSuccess = MessageBox("success", "Sucesso", messagesContent.process.messageSuccess)
const alertFields = MessageBox("warning", "Atenção", messagesContent.common.alertFields)

export default function Processo(){

    const toaster = useToaster();

    const SIZE = 2;
    const PAGE = 10;

    const [process, setProcess] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [lostConnection, setLostConnection] = useState(false);

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openLote, setOpenLote] = React.useState(false);
    const [openClassification, setOpenClassification] = React.useState(false);

    const [numProcess, setNumProcess] = useState('')
    const [numProcessSearch, setNumProcessSearch] = useState('')

    const [category, setCategory] = useState('')
    const [categorySearch, setCategorySearch] = useState('')

    const [subject, setSubject] = useState('')
    const [subjectSearch, setSubjectSearch] = useState('')

    const [subsubject, setSubsubject] = useState('')
    const [subsubjectSearch, setSubsubjectSearch] = useState('')

    const [obs, setObs] = useState('')
    const [obsSearch, setObsSearch] = useState('')

    const [listProcesses, setListProcesses] = useState()
    const [allFieldsFill, setAllFieldsFill] = useState([false, false, false, false]);
    const [reportFieldsFill, setReportFieldsFill] = useState([false, false, false]);
    const [categoryList, setCategoryList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [subsubjectList, setSubsubjectList] = useState([]);
    const [allsubsubject, setAllSubsubject] = useState([]);
    const [atualItem, setAtualItem] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);  
    const handleModalClose = () => setModalOpen(false);
    const [modalReport, setModalReport] = useState(false);
    const handleModalReportOpen = () => setModalReport(true);
    const handleModalReportClose = () => setModalReport(false);
    const handleModalClassificationOpen = () => setOpenClassification(true);
    const handleModalClassificationClose = () => setOpenClassification(false);

    //pagination states
    const limitOptions = [25, 50, 100];
    const [layout, setLayout] = React.useState(['total', '-', 'limit', '|', 'pager', 'skip']);
    const [total, setTotal] = React.useState(1);
    const [activePage, setActivePage] = React.useState(1);
    const [limit, setLimit] = React.useState(25);

    // report error
    const [typeError, setTypeError] = React.useState();
    const [justify, setJustify] = React.useState('');
    const [newCategory, setNewCategory] = React.useState();

    //state tipo de erro
    const reportType = [{
        label: 'Núcleo incorreto', value: 0
    }]

    //parametro do get
    const { id } = useParams();

    const [loadingLote, setLoadingLote] = React.useState(false);
    
    //fast keys
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === "1") {
                event.preventDefault();
                setOpenLote(false);
                setOpen(true);
            }else if(event.ctrlKey && event.key === "2"){
                event.preventDefault();
                setOpen(false);
                handleClear();
                setOpenLote(true);
            }else if(event.ctrlKey && event.key === "3"){
                event.preventDefault();
                if(open){
                    handleSubmit();
                }else if(openEdit){
                    handleUpdate();
                }else if(openLote){
                    saveLote();
                }
            }else if(event.ctrlKey && event.key === "4"){
                event.preventDefault();
                setOpenEdit(false);
                setOpen(false);
                setOpenLote(false); 
            }else if(event.ctrlKey && event.key === "0"){
                event.preventDefault();
                handleClear();
            }else if(event.ctrlKey && event.key === "7"){
                event.preventDefault();
                handleClearSearch();
            }else if(event.ctrlKey && event.key === "8"){
                event.preventDefault();
                searchParams();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    },);
    
    //busca lista de processos
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
          try {
            let resultado = [];
            if (  hasRole('view_all_process')) {
                resultado = await getProcessList(activePage, limit, true);
            }else if ( hasRole('view_process')) {
                resultado = await getProcessParams("", "", "", "", getAdditionalInfoDescript().id, "", activePage, limit, true);
            }
            if(resultado.status != 200){
                if(resultado.response.status == 404){
                    setProcess([]);
                    setIsLoading(false);
                }else{
                    setIsLoading(false);
                    setLostConnection(true);
                }
            }else{
                setProcess(resultado.data.results);
                setTotal(resultado.data.count);
                console.log(process)
                setIsLoading(false);
            }
          } catch (error) {
            console.error('Erro ao buscar dados assíncronos:', error);
          }
        };
        fetchData();

    },[]); 

    //busca lista de categorias
    useEffect(() => {
        const fetchData = async () => {
            try {
              const resultado = await getCategoryList();
              if(resultado.status != 200){
                  setCategoryList([]);
              }else{
                    //mudança de estrutura
                  setCategoryList(resultado.data.results.map(category => (
                    {label: category.name, value: category.id})
                  ));
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
          };
          fetchData();
    }, [])

    //busca lista de assuntos
    useEffect(() => {
        const fetchData = async () => {
            try {
              const resultado = await getSubjectList();
              if(resultado.status != 200){
                  setSubjectList([]);
              }else{
                  //mudança de estrutura
                  setSubjectList(resultado.data.results.map(category => (
                    {label: category.name, value: category.id})
                  ));
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
          };
          fetchData();
    }, [])

    //busca lista de subassuntos por assunto
    useEffect(() => {
        const fetchData = async () => {
            try {
              const resultado = await getSubSubjectsByIdSubject(subject);
              if(resultado.status != 200){
                  setSubsubjectList([]);
              }else{
                    //mudança de estrutura
                    setSubsubjectList(resultado.data.results.map(category => (
                    {label: category.name, value: category.id})
                  ));
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
          };
          if(subject != ''){
            fetchData();
          }else{
            setSubsubjectList([]);
          }
    }, [subject])

    //busca lista de subassuntos
    useEffect(() => {
        const fetchData = async () => {
            try {
              const resultado = await getSubSubjectList(subject);
              if(resultado.status != 200){
                setAllSubsubject([]);
              }else{
                //mudança de estrutura
                setAllSubsubject(resultado.data.results.map(subsubject => (
                    {label: subsubject.name, value: subsubject.id})
                  ));
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
        }

        fetchData();
    }, [])

    //validator
    useEffect(() => {
        try{
            if(numProcess != '' && numProcess.match(/\d{7}-\d{2}\.\d{4}\.\d\.\d{2}\.\d{4}/)){
                document.getElementById('masked-input').style.borderColor = 'green';
                setAllFieldsFill(() => {allFieldsFill[0] = true; return allFieldsFill;});
            }else{
                document.getElementById('masked-input').style.borderColor = 'red';
                setAllFieldsFill(() => {allFieldsFill[0] = false; return allFieldsFill;});
            }

            if(category != '' && category != null && category != undefined){
                document.getElementById('select-nucleo').style.borderColor = 'green';
                setAllFieldsFill(() => {allFieldsFill[1] = true; return allFieldsFill;});
            }else{
                document.getElementById('select-nucleo').style.borderColor = 'red';
                setAllFieldsFill(() => {allFieldsFill[1] = false; return allFieldsFill;});
            }

            // if(openClassification){
            //     if(subject != '' && subject != null && subject != undefined){
            //         document.getElementById('select-assunto').style.borderColor = 'green';
            //         setAllFieldsFill(() => {allFieldsFill[2] = true; return allFieldsFill;});
            //     }else{
            //         document.getElementById('select-assunto').style.borderColor = 'red';
            //         setAllFieldsFill(() => {allFieldsFill[2] = false; return allFieldsFill;});
            //     }

            //     if(subsubject != '' && subsubject != null && subsubject != undefined){
            //         document.getElementById('select-subassunto').style.borderColor = 'green';
            //         setAllFieldsFill(() => {allFieldsFill[3] = true; return allFieldsFill;});
            //     }else{
            //         document.getElementById('select-subassunto').style.borderColor = 'red';
            //         setAllFieldsFill(() => {allFieldsFill[3] = false; return allFieldsFill;});
            //     }
            // }
            // if(obs != ''){
            //     document.getElementById('obs').style.borderColor = 'green';
            //     setAllFieldsFill(() => {allFieldsFill[4] = true; return allFieldsFill;});
            // }else{
            //     document.getElementById('obs').style.borderColor = 'red';
            //     setAllFieldsFill(() => {allFieldsFill[4] = false; return allFieldsFill;});
            // }

        }catch(error){
            
        }


    }, [numProcess, category, subject, subsubject, obs, open])

    //validator modal classification
    useEffect(() => {
        try{
            if(subject != '' && subject != null && subject != undefined){
                document.getElementById('select-assunto-classification').style.borderColor = 'green';
                setAllFieldsFill(() => {allFieldsFill[2] = true; return allFieldsFill;});
            }else{
                document.getElementById('select-assunto-classification').style.borderColor = 'red';
                setAllFieldsFill(() => {allFieldsFill[2] = false; return allFieldsFill;});
            }

            if(subsubject != '' && subsubject != null && subsubject != undefined){
                document.getElementById('select-subassunto-classification').style.borderColor = 'green';
                setAllFieldsFill(() => {allFieldsFill[3] = true; return allFieldsFill;});
            }else{
                document.getElementById('select-subassunto-classification').style.borderColor = 'red';
                setAllFieldsFill(() => {allFieldsFill[3] = false; return allFieldsFill;});
            }
        }catch(error){
            console.log(error)
        }

    }, [subject, subsubject, openClassification])

    //envia processo
    const handleSubmit = () => {
        console.log(allFieldsFill)
        if(!allFieldsFill[0] && !allFieldsFill[1]){
            toaster.push(alertFields, {duration: 5000 })
            return;
        }

        const verifyProcessExist = async () => {
            try{
                //mudança de estrutura
                const processData = { number: numProcess, category_id: category}
                if(subject != '' && subject != null && subject != undefined){
                    processData['subject_id'] = subject
                }

                if(subsubject != '' && subsubject != null && subsubject != undefined){
                    processData['subsubject_id'] = subsubject
                }

                if(obs != '' && obs != null && obs != undefined){
                    processData['obs'] = obs
                }
                const saveData = async () => {
                    try {
                        //mudança de estrutura
                        const resultado = await saveProcess(processData);
                        console.log("resultado", resultado)
                        if(resultado.status != 201){
                            if(resultado.response.status == 400){
                                toaster.push(
                                    MessageBox("warning", "Atenção", "Processo já cadastrado."),
                                    {duration: 5000 }
                                )
                            }else{
                                toaster.push(messageError, {duration: 5000 })
                            }
                        }else{
                            toaster.push(messageSuccess, {duration: 5000 })
                            setProcess([resultado.data, ...process]);
                            setTotal(total + 1);
                            setOpen(false);
                        }
                    } catch (error) {
                        console.error('Erro ao buscar dados assíncronos:', error);
                    }
                };

                saveData();
            }catch(error){

            }
        }

        verifyProcessExist();

    }

    //deleta processo
    const handleDelete = () => {

        //await for modal response
        const handleDelete = async () => {
            try {
              const resultado = await deleteProcess(atualItem.id);
              if(resultado.status != 204){
                toaster.push(errorDeleteAlert, {duration: 5000 })
              }else{
                toaster.push(successDeleteAlert, {duration: 5000 })
                setProcess(process.filter(processo => processo.id !== atualItem.id))
                setTotal(total - 1);
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
          };

          handleDelete();
          handleModalClose();
    }	

    //limpa campos
    function handleClear() {
        
        setNumProcess('');
        setCategory('');
        setSubject('');
        setSubsubject('');
        setObs('');
        setAllFieldsFill([false, false, false, false]);
    }

    //limpa campos do search
    function handleClearSearch() {
        setNumProcessSearch('');
        setCategorySearch('');
        setSubjectSearch('');
        setSubsubjectSearch('');
        setObsSearch('');
    }

    // fecha o modal
    const handleEditModalClose = () => {
        
        setOpenEdit(false);
        handleClear();
    }

    //abre modal de edição
    const handleClickEdit = (number, category, subject, subsubject, obs) => {
        setNumProcess(number);
        setCategory(category?.id);
        setSubject(subject?.id);
        setSubsubject(subsubject?.id);
        // if(category != null){
        //     setCategory(category.id);
        // }
        // if(subject != null){
        //     setSubject(subject.id);
        // }
        // if(subsubject != null){
        //     setSubsubject(subsubject.id);
        // }
        setObs(obs);
        setOpenEdit(true);
    }

    //abre modal de classificacao
    const handleClickClassification = (number, category, subject, subsubject, obs) => {
        setNumProcess(number);
        setCategory(category?.id);
        setSubject(subject?.id);
        setSubsubject(subsubject?.id);
        setObs(obs);
        handleModalClassificationOpen();
    }

    //atualiza dados
    const handleUpdate = () => {
       
        if(allFieldsFill[0] == false || allFieldsFill[1] == false){
            toaster.push(alertFields, {duration: 5000 })
            return;
        }
        
        // se ocorreu mudança no subassunto ou assunto
        const processData = { number: numProcess, category_id: category, subject_id: null, subsubject_id: null, obs: obs }
        
        if(subject != '' && subject != null && subject != undefined){
            processData.subject_id = subject;
        }

        if(subsubject != '' && subsubject != null && subsubject != undefined){
            processData.subsubject_id = subsubject;
        }

        const updateData = async () => {
            try {
              const resultado = await updateProcess(atualItem.id,processData);
              if(resultado.status != 200){
                toaster.push(messageUpdateError, {duration: 5000 })
              }else{
                toaster.push(messageUpdateSuccess, {duration: 5000 })
                setProcess([resultado.data, ...process.filter(processo => processo.id !== atualItem.id)]);
                setOpenEdit(false);
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
        }

        updateData()
    }

    // classifica processo
    const handleClassification = () => {

        if(allFieldsFill[2] == false || allFieldsFill[3] == false){
            toaster.push(alertFields, {duration: 5000 })
            return;
        }

        const processData = { number: numProcess, category_id: category, subject_id: subject, subsubject_id: subsubject, obs: obs, classification_date: new Date()}
        const updateData = async () => {
            try {
              const resultado = await updateProcess(atualItem.id, processData);
              if(resultado.status != 200){
                toaster.push(messageUpdateError, {duration: 5000 })
              }else{
                toaster.push(messageUpdateSuccess, {duration: 5000 })
                setProcess([resultado.data, ...process.filter(processo => processo.id !== atualItem.id)]);
                handleModalClassificationClose();
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
        }

        updateData();
    }

    // verifica se o processo tem formato valido
    const validaFormatoProcesso = (processNumber) => {
        const regex = new RegExp('^\\d{7}-\\d{2}.\\d{4}.\\d{1}.\\d{2}.\\d{4}$');
        if(regex.test(processNumber) && processNumber.length === 25){
          return true;
        }
        return false
    }

    // copia erro
    const handleCopy = (processos) => () => {
        var copyText = processos.join('\n');
        navigator.clipboard?.writeText(copyText);
    }

    //faz a requisição do lote
    const saveLote = async () => {
        setLoadingLote(true);
        try{
            if(listProcesses == '' || listProcesses.replace(' ', '' ) == '' || category == '' || category == null || category == undefined){
                toaster.push(alertFields, {duration: 5000 })
                return;
            }else{
                const processToList = listProcesses.split('\n');
                processToList.map((process) => process.trim());
                processToList.filter((process) => process != '');

                var processError = []
                var processPass = []

                for(let i = 0; i < processToList.length; i++){
                    if(!validaFormatoProcesso(processToList[i])){
                        processError.push(processToList[i])
                    }else{
                        if(!(processToList[i] in processPass)){
                            processPass.push(processToList[i])
                        }
                    }
                }

                processError = processError.filter((process) => process != '');

                if(processPass.length != 0){
                    const listaNumProcess = [...new Set(processPass)];
                    try{
                        let resultado = await cadastrarProcessoLote(listaNumProcess, category);

                        var listNumbers = resultado.map((process) => process.number);

                        var processNaoCadastrados = []
                        listaNumProcess.forEach(
                            (number) => {
                                if(!listNumbers.includes(number)){
                                    processNaoCadastrados.push(number)
                                }
                            }
                        )

                        const processosCadastrados = resultado.filter((process) => listProcesses.includes(process.number));

                        setProcess([...process, ...processosCadastrados]);

                        console.log(processError)
                        setLoadingLote(false);
                        if(processNaoCadastrados.length != 0 || processError.length != 0){
                            toaster.push(
                                <Message showIcon type={"error"} closable>
                                    <strong>{"Erro"}!</strong> Não foi possível criar os seguintes processos. Os demais processos foram criados com sucesso. 
                                    {
                                        //copy to clipboard
                                    }
                                    <button style={{ marginLeft: '40px' }} onClick={handleCopy(processNaoCadastrados.concat(processError))}>Copiar</button>
                                    {
                                        processNaoCadastrados.map((process, index) => {
                                            return(<span className="pl-6"><br></br>{process} - processo já cadastrado</span>)
                                        })
                                    }
                                    {
                                        processError.map((process, index) => {
                                            return (<span className="pl-6"><br></br>{process} - processo com formato inválido </span>)
                                        })
                                    }
                                </Message>,
                            {duration: 50000 }
                            )
                        }else{
                            toaster.push(
                                MessageBox("success", "Sucesso", "Todos os processos foram criados com sucesso."),
                                {duration: 20000 }
                            )
                            setOpenLote(false);
                        }
                    }catch(error){
                    
                    }

                }else{
                    console.log("process erro",processError)

                    toaster.push(
                        <Message showIcon type={"error"} closable>
                            <strong>{"Erro"}!</strong> Não foi possível criar os processos. Verifique se os processos estão no formato correto.
                            {/* <button style={{ marginLeft: '40px' }} onClick={handleCopy(processError)}>Copiar</button> */}
                            {
                                processError.map((process, index) => {
                                    return (<span className="pl-6"><br></br>{process} - processo com formato inválido </span>)
                                })
                            }
                        </Message>,
                    {duration: 50000 }
                    )
                }
            }
        }catch(error){
            console.log(error)
            toaster.push(messageError, {duration: 5000 })
        } 
    }

    //mudança
    useEffect(() => {

        console.log("esta executando")

        const fetchData = async () => {
            try {
              //const resultado = await getProcessList(activePage, limit, true);
              let resultado = [];
              if ( hasRole('view_all_process')) {
                    resultado = await getProcessList(activePage, limit, true);
                }else if ( hasRole('view_process')) {
                    resultado = await getProcessParams("", "", "", "", getAdditionalInfoDescript().id, "", activePage, limit, true);
                }
              if(resultado.status != 200){
                  setProcess([]);
                  setIsLoading(false);
              }else{
                  //mudança de estrutura
                  setProcess(resultado.data.results);
                  setTotal(resultado.data.count);
                  setIsLoading(false);
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
        }
        fetchData();
    }, [activePage, limit])

    //exporta para excel
    const exportToExcel = () => {
        var data = [
            ["Nº do processo", "Núcleo", "Assunto", "SubAssunto", "Observação"],
        ]

        data = data.concat(process.map((processo) => {
            var sub = "";
            var subsub = "";
            try{
                sub = processo.subsubject.name;
            }catch{

            }
            try{
                subsub = processo.subsubject.name;
            }catch{

            }

            return [processo.number, processo.category.name, sub, subsub, processo.obs]
        }))

        var workbook = XLSX.utils.book_new();
        var worksheet = XLSX.utils.aoa_to_sheet(data);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados');
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        var blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        window.saveAs(blob, 'processos.xlsx');

    }

    // procesa o texto do textarea do lote
    const processaLote = (value) => {
        var lines = value.split('\n');
        const formattedLines = lines.map((line, index) => {
            const trimmedLine = line.trim();
            return trimmedLine.substring(0, 25)
        })

        setListProcesses(formattedLines.join('\n'));

    }

    //busca com parametros
    const searchParams = () => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
              let resultado = [];
                if (  hasRole('view_all_process')) {
                    resultado = await getProcessParams(numProcessSearch, categorySearch, subjectSearch, subsubjectSearch, '', obsSearch, activePage, limit, true);
                }else if ( hasRole('view_process')) {
                    resultado = await getProcessParams(numProcessSearch, categorySearch, subjectSearch, subsubjectSearch, getAdditionalInfoDescript().id, obsSearch, activePage, limit, true);
                }
            if(resultado.status != 200){
                setProcess([]);
                setIsLoading(false);
              }else{
                //mudança de estrutura
                setProcess(resultado.data.results);
                setTotal(resultado.data.count);
                setIsLoading(false);
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
        }
        fetchData();
    }

    // validador de campos do report de erro
    useEffect(() => {
        console.log(typeError, justify)
        try{
            if(typeError === '' || typeError == null || typeError == undefined){
                document.getElementById('select-typeError').style.borderColor = 'red';
                setReportFieldsFill(() => {reportFieldsFill[0] = false; return reportFieldsFill;});
            }else{
                document.getElementById('select-typeError').style.borderColor = 'green';
                setReportFieldsFill(() => {reportFieldsFill[0] = true; return reportFieldsFill;});
            }

            if(justify == '' || justify == null || justify == undefined){
                document.getElementById('input-justify').style.borderColor = 'red';
                setReportFieldsFill(() => {reportFieldsFill[1] = false; return reportFieldsFill;});
            }else{
                document.getElementById('input-justify').style.borderColor = 'green';
                setReportFieldsFill(() => {reportFieldsFill[1] = true; return reportFieldsFill;});
            }

            if(newCategory == '' || newCategory == null || newCategory == undefined){
                document.getElementById('select-newCategory').style.borderColor = 'red';
                setReportFieldsFill(() => {reportFieldsFill[2] = false; return reportFieldsFill;});
            }else{
                document.getElementById('select-newCategory').style.borderColor = 'green';
                setReportFieldsFill(() => {reportFieldsFill[2] = true; return reportFieldsFill;});
            }
        }catch(error){
            
        }
    }, [typeError, justify, newCategory,modalReport])

    //envia o report erro
    const handleReport = () => {
        if(!reportFieldsFill.includes(false)){

            const fetch = async () => {
                try {
                const resultado = await requestChangeProcessCategory(atualItem.id, newCategory, getAdditionalInfoDescript().id, justify);
                if(resultado.status != 201){
                    toaster.push(MessageBox("error", "Erro", "Ocorreu um erro em sua solicitação"), {duration: 5000 })
                }else{
                    toaster.push(MessageBox("success", "Sucesso", "Solicitação enviada"), {duration: 5000 })
                    setModalReport(false);
                }
                } catch (error) {
                    console.error('Erro ao buscar dados assíncronos:', error);
                }
            }

            // testa se o processo já foi reportado
            const fetchVerify = async () => {
                try {
                    const resultado = await getRequests(atualItem.id, '', getAdditionalInfoDescript().id, 'PENDENTE', 'process');
                    if(resultado.status == 200){
                        if(resultado.data.results.length != 0){
                            toaster.push(MessageBox("warning", "Atenção", "Processo já reportado"), {duration: 5000 })
                            setModalReport(false);
                            return;
                        }else{
                            fetch();
                        }
                    }
                } catch (error) {
                    console.error('Erro ao buscar dados assíncronos:', error);
                }
            }
            fetchVerify();
        }else{
            toaster.push(alertFields, {duration: 5000 })
        }
    }

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container fluid>
                <div className="header-body">
                </div>
                <div className="bg-white p-4 rounded">
                    <h3 className="mb-4 mt-2 ml-1">Pesquisa</h3>
                    <div className="row">
                        <div className="col-4">
                            <Label>Nº do processo</Label><MaskedInput placeholder="Digite o Nº do processo" value={numProcessSearch} mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/, /\d/, '-', /\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/,'.',/\d/,'.',/\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/]} onChange={setNumProcessSearch}></MaskedInput>
                        </div>
                        <div className="col-2">
                            <Label>Núcleo</Label><Select value={categorySearch} onChange={setCategorySearch} size="lg" className="w-100" placeholder="Selecione um núcleo" data={categoryList}></Select>
                        </div>
                        <div className="col-6">
                            <Label>Observação</Label><Input value={obsSearch} onChange={setObsSearch} size="lg" placeholder="Digite a observação"></Input>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-3">
                            <Label>Assunto</Label><Select value={subjectSearch} onChange={setSubjectSearch} size="lg" className="w-100" placeholder="Selecione um assunto" data={subjectList}></Select>
                        </div>
                        <div className="col-3">
                            <Label>SubAssunto</Label><Select size="lg" value={subsubjectSearch} onChange={setSubsubjectSearch} className="w-100" placeholder="Selecione um subassunto" data={allsubsubject}></Select>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <Button color="warning" className="mr-3" size="md" onClick={handleClearSearch}><i class="fa-solid fa-trash mr-2"></i>Limpar</Button>
                        <Button color="primary" size="md" onClick={searchParams}><i class="fa-solid fa-magnifying-glass mr-2" ></i>Pesquisar</Button>
                    </div>
                </div>
                </Container>
                
            </div>
            
            <Container className="mt--7" style={{ minHeight: '70vh' }} fluid>
        {/* Table */}
            <Row>
                <div className="col">
                    <Card className="shadow">
                    <CardHeader className="border-0 d-flex flex-row align-items-center justify-content-between">
                        <h3 className="mb-0">Processo</h3>
                        <div>
                            <ProtectedRoute permissionsAccepted={['add_process']} mode={'component'}>
                                <Button color="primary" className='mr-3' size="md" onClick={() => {setOpen(true);
                                        handleClear();
                                }} ><i className="fas fa-plus mr-2 "  />Processo único</Button>
                                <Button color="primary" className='mr-3' size="md" onClick={() => {
                                    handleClear();
                                    setOpenLote(true)}} ><i class="fa-solid fa-list mr-2" />Processo em lote</Button>
                                {/* <Button style={{ backgroundColor: '#10b981', color: 'white' }} size="md" onClick={exportToExcel} ><img src={excelicon} width={20} height={20} style={{ marginRight: 15 }}/>Exportar</Button> */}
                            </ProtectedRoute>
                        </div>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Nº do processo</th>
                            <th scope="col">Núcleo</th>
                            <th scope="col">Assunto</th>
                            <th scope="col">SubAssunto</th>
                            <th scope="col">Observação</th>
                            <ProtectedRoute permissionsAccepted={['change_process', 'delete_process', 'classification_process']} mode={'component'}>
                                <th scope="col">Ações </th>
                            </ProtectedRoute>
                        </tr>
                        </thead>
                        <tbody>
                            {
                            !isLoading &&
                                process.map((processo) => {
                                    
                                    return (<tr key={processo.id}>
                                        <th scope="row">
                                            {/* call uniq process */}
                                            <NavLink to={`/gerenciar/processo/${processo.number}`}
                                                >
                                                <span className="mb-0 text-sm">
                                                    {processo.number}
                                                </span>
                                            </NavLink>
                                        </th>
                                        <td>
                                            {
                                            processo.category == null ? "" :
                                            processo.category.name}</td>
                                        <td>{
                                            processo.subject == null ? "" :
                                            processo.subject.name}</td>
                                        <td>
                                            {
                                            processo.subsubject == null ? "" :
                                            processo.subsubject.name}
                                        </td>
                                        <td>
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2">{processo.obs}</span>
                                        </div>
                                        </td>
                                        <ProtectedRoute permissionsAccepted={['change_process', 'delete_process', 'classification_process']} mode={'component'}>
                                            <td>
                                                {/* <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                                                    <Tooltip>
                                                        Reportar erro
                                                    </Tooltip>
                                                }>
                                                    <Button style={{ backgroundColor:  '#fb923c', color: 'white' }} className='mr-2' onClick={() => {
                                                        
                                                        setAtualItem(processo);
                                                        handleModalReportOpen();
                                                        setJustify();
                                                        setTypeError();
                                                        setNewCategory();
                                                        setReportFieldsFill([false, false, false]);
                                                        
                                                    }}>
                                                        <i class="fa-solid fa-triangle-exclamation"></i>
                                                    </Button>
                                                </Whisper> */}
                                                <ProtectedRoute permissionsAccepted={['classification_process']} mode={'component'}>
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                                                        <Tooltip>
                                                            Classificar processo
                                                        </Tooltip>
                                                    }>
                                                        <Button style={{ backgroundColor: '#38bdf8', color: 'white' }} className='mr-2' onClick={() => {
                                                            setAtualItem(processo);
                                                            handleClickClassification(processo.number, processo.category, processo.subject, processo.subsubject, processo.obs);
                                                        }}>
                                                            <i class="fa-solid fa-stamp"></i>
                                                        </Button>
                                                    </Whisper>
                                                </ProtectedRoute>

                                                <ProtectedRoute permissionsAccepted={['change_process']} mode={'component'}>

                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                                                        <Tooltip>
                                                            Editar processo
                                                        </Tooltip>
                                                    }>
                                                        <Button className='mr-2' onClick={() => {
                                                            setAtualItem(processo);
                                                            handleClickEdit( 
                                                            processo.number, processo.category, processo.subject, 
                                                            processo.subsubject, processo.obs)}}>
                                                            <i class="fa-solid fa-pen"></i>
                                                        </Button>
                                                    </Whisper>
                                                </ProtectedRoute>
                                                <ProtectedRoute permissionsAccepted={['delete_process']} mode={'component'}>
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                                                            <Tooltip>
                                                                Excluir processo
                                                            </Tooltip>
                                                        }>
                                                        <Button color="danger" style={{ backgroundColor: '#f87171', color: 'white' }} onClick={() => {
                                                                    setAtualItem(processo);
                                                                    setModalOpen(true);}}>
                                                            <i class="fa-solid fa-trash"></i>
                                                        </Button>
                                                    </Whisper>
                                                </ProtectedRoute>
                                            </td>
                                        </ProtectedRoute>
                                    </tr>)
                                })
                            }
                        </tbody>
                    </Table>
                    
                    {isLoading &&<div className="mb-4 mt-4 w-100 d-flex align-items-center justify-content-center flex-column" >
                        {CustomLoadingOverlay()}
                    </div>}
                    {!isLoading && lostConnection &&
                        <div className="mb-4 mt-4 w-100 d-flex align-items-center justify-content-center flex-column" >
                            { LostConnectionOverlay()}
                        </div>
                    }
                    {!isLoading && !lostConnection && process.length == 0 &&
                        <div className="mb-4 mt-4 w-100 d-flex align-items-center justify-content-center flex-column" >
                        {CustomNoRowsOverlay()}
                         </div>
                    }
                    <CardFooter className="py-4">
                        <nav aria-label="...">
                        <CustomProvider locale={ptBR}>
                            <Pagination
                                    layout={layout}
                                    size={'xs'}
                                    prev={true}
                                    next={true}
                                    first={true}
                                    last={true}
                                    ellipsis={true}
                                    boundaryLinks={true}
                                    total={total}
                                    limit={limit}
                                    limitOptions={limitOptions}
                                    maxButtons={4}
                                    activePage={activePage}
                                    onChangePage={setActivePage}
                                    onChangeLimit={setLimit}
                                />
                        </CustomProvider>
                        </nav>
                    </CardFooter>
                    </Card>
                </div>
            </Row>
            {/* adicionar processo */}
            <ProtectedRoute permissionsAccepted={['add_process']} mode={'component'}>
                <Drawer backdrop="static" open={open} onClose={() => setOpen(false)}>
                    <Drawer.Header className="pt-4">
                        <h4>Cadastro de processo</h4>
                    </Drawer.Header>
                    <Drawer.Body>
                            <Row className="mb-4">
                                <h4>Nº do processo</h4>
                                <MaskedInput placeholder="Digite o Nº do processo" value={numProcess} id="masked-input" mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/, /\d/, '-', /\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/,'.',/\d/,'.',/\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/]} onChange={setNumProcess}></MaskedInput>
                            </Row>
                            <Row className="mb-4">
                                <h4>Núcleo</h4>
                                <Select id="select-nucleo" value={category} onChange={setCategory} placeholder="Selecione um núcleo" data={categoryList} style={{ width: "100%" }} size="lg">
                                    
                                </Select>
                            </Row>
                            <Row className="mb-4">
                                <h4>Assunto</h4>
                                <Select id="select-assunto" value={subject} onChange={setSubject} data={subjectList} placeholder="Selecione um Assunto" style={{ width: "100%" }} size="lg"
                                onClean={() => {
                                    setSubject('');
                                    setSubsubject('');
                                }}>  
                                </Select>
                            </Row>
                            <Row className="mb-4">
                                <h4>Subassunto</h4>
                                <Select id="select-subassunto" value={subsubject} onChange={setSubsubject} data={subsubjectList} placeholder="Selecione um Subassunto" style={{ width: "100%" }} size="lg">
                                    
                                </Select>
                            </Row>
                            <Row className="mb-4">
                                <h4>Observação</h4>
                                <Input placeholder="Digite uma observação" id="obs" as="textarea" value={obs} rows={7} onChange={setObs}></Input>
                            </Row>
                            <Row>
                                <Button onClick={handleClear} style={{ backgroundColor: '#fb923c', color: 'white' }} className="position-absolute end-0 mb-4">Limpar campos</Button>
                                <Button onClick={handleSubmit} color="primary" className="position-absolute end-0 right-0 mr-5 mb-4">Salvar</Button>
                            </Row>
                    </Drawer.Body>
                </Drawer>
            </ProtectedRoute>

            {/* editar processo */}
            <ProtectedRoute permissionsAccepted={['change_process']} mode={'component'}>
                <Drawer backdrop="static" open={openEdit} onClose={handleEditModalClose}>
                    <Drawer.Header className="pt-4">
                        <h4>Edição de processo</h4>
                    </Drawer.Header>
                    <Drawer.Body>
                        <Row className="mb-4">
                            <h4>Nº do processo</h4>
                            <MaskedInput placeholder="Digite o Nº do processo" value={numProcess} id="masked-input" mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/, /\d/, '-', /\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/,'.',/\d/,'.',/\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/]} onChange={setNumProcess}></MaskedInput>
                        </Row>
                        <Row className="mb-4">
                            <h4>Núcleo</h4>
                            <Select id="select-nucleo" value={category} onChange={setCategory} placeholder="Selecione um núcleo" data={categoryList} style={{ width: "100%" }} size="lg">
                                
                            </Select>
                        </Row>
                        <Row className="mb-4">
                            <h4>Assunto</h4>
                            <Select id="select-assunto" value={subject} onChange={setSubject} data={subjectList} placeholder="Selecione um Assunto" style={{ width: "100%" }} size="lg"
                                onClean={() => {
                                    setSubject('');
                                    setSubsubject('');
                                }}
                            >
                                
                            </Select>
                        </Row>
                        <Row className="mb-4">
                            <h4>Subassunto</h4>
                            <Select id="select-subassunto" value={subsubject} onChange={setSubsubject} data={subsubjectList} placeholder="Selecione um Subassunto" style={{ width: "100%" }} size="lg">
                                
                            </Select>
                        </Row>
                        <Row className="mb-4">
                            <h4>Observação</h4>
                            <Input placeholder="Digite uma observação" id="obs" as="textarea" value={obs} rows={7} onChange={setObs}></Input>
                        </Row>
                        <Row className='d-flex justify-content-end'>
                            <Button onClick={handleUpdate} color="primary" className="bottom-0 end-0 right-0 mb-4">Atualizar</Button>
                        </Row>
                    </Drawer.Body>
                </Drawer>
            </ProtectedRoute>

            {/* cadastrar processo em lote */}
            <ProtectedRoute permissionsAccepted={['add_process']} mode={'component'}>
                <Drawer backdrop="static" open={openLote} onClose={() => {setOpenLote(false)}}>
                    <Drawer.Header className="pt-4">
                        <h4>Cadastro de processo em lote</h4>
                    </Drawer.Header>
                    <Drawer.Body>
                            <Row className="mb-4">
                                <h4>Lista de processos</h4>
                                <Input id="lista-processo-lote" placeholder="1111111-11.1111.1.11.1111&#10;2222222-22.2222.2.22.2222&#10;3333333-33.3333.3.33.3333&#10;..." as="textarea" value={listProcesses} rows={25} onChange={(e) => {processaLote(e)}}></Input>
                            </Row>
                            <Row className="mb-4">
                                <h4>Núcleo</h4>
                                <Select id="select-nucleo-lote" value={category} onChange={setCategory} placeholder="Selecione um núcleo" data={categoryList} style={{ width: "100%" }} size="lg">
                                    
                                </Select>
                                <h6>O núcleo será replicado para todos os processos</h6>
                            </Row>
                            <Row>
                                <Button onClick={saveLote} color="primary" className="position-absolute end-0 right-0 mr-5 mb-4">Salvar</Button>
                            </Row>
                    </Drawer.Body>
                </Drawer>
            </ProtectedRoute>

            <ProtectedRoute permissionsAccepted={['delete_process']} mode={'component'}>
                <Modal open={modalOpen} onClose={handleModalClose}>
                    <Modal.Header>
                    <Modal.Title>Deletar processo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Deseja realmente deletar o processo? Essa ação é irreversível.
                    </Modal.Body>
                    <Modal.Footer>
                    <Button size="sm" onClick={handleModalClose} appearance="subtle">
                        Cancelar
                    </Button>
                    <Button size="sm" style={{ backgroundColor: '#f87171' }} onClick={handleDelete} appearance="primary">
                        Confirmar
                    </Button>
                    </Modal.Footer>
                </Modal>
            </ProtectedRoute>

            {/* proteger depois modal report*/}
            <Modal open={modalReport} onClose={handleModalReportClose} role="alertdialog" closable={false} backdrop={'static'}>
                <Modal.Header>
                <Modal.Title>Reportar erro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: 25 }}>
                            <Label>Tipo de erro</Label>
                            <Select id="select-typeError" style={{ width: 224 }} placeholder="Tipo de erro" data={reportType} value={typeError} onChange={setTypeError} size="sm"></Select>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {
                                typeError == reportType[0].value && 
                                <>
                                    <Label>Novo núcleo</Label>
                                    <Select id="select-newCategory" style={{ width: 224 }} placeholder="Selecione um núcleo" data={categoryList.filter((item) => 
                                    {
                                        return item.value != atualItem?.category.id
                                    }
                                    )} value={newCategory} onChange={setNewCategory} size="sm"></Select>
                                </>
                            }
                        
                        </div>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <Label>Justificativa</Label>
                        <Input id="input-justify" as="textarea" rows={4} placeholder="Digite uma justificativa" value={justify} onChange={setJustify}></Input>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button size="sm" onClick={handleModalReportClose} appearance="subtle">
                    Cancelar
                </Button>
                <Button size="sm" color="primary" onClick={handleReport} appearance="primary">
                    Confirmar
                </Button>
                </Modal.Footer>
            </Modal>

            {/* modal de classificacao */}
            <Drawer backdrop="static" open={openClassification} onClose={handleModalClassificationClose}>
                    <Drawer.Header className="pt-4">
                        <h4>Classificação de processo</h4>
                    </Drawer.Header>
                    <Drawer.Body>
                        <Row className="mb-4">
                            <h4>Nº do processo</h4>
                            <InputStrap disabled={true} placeholder="Digite o Nº do processo" value={numProcess} id="masked-input-classification" mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/, /\d/, '-', /\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/,'.',/\d/,'.',/\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/]} onChange={setNumProcess}></InputStrap>
                        </Row>
                        <Row className="mb-4">
                            <h4>Núcleo</h4>
                            <InputStrap disabled={true} id="select-nucleo-classification" value={categoryList.find((c) => {
                                return c.value == category
                            })?.label} onChange={setCategory} placeholder="Selecione um núcleo" style={{ width: "100%" }} size="lg">
                            </InputStrap>
                        </Row>
                        <Row className="mb-4">
                            <h4>Assunto</h4>
                            <Select id="select-assunto-classification" value={subject} onChange={setSubject} data={subjectList} placeholder="Selecione um Assunto" style={{ width: "100%" }} size="lg"
                                onClean={() => {
                                    setSubject('');
                                    setSubsubject('');
                                }}
                            >
                                
                            </Select>
                        </Row>
                        <Row className="mb-4">
                            <h4>Subassunto</h4>
                            <Select id="select-subassunto-classification" value={subsubject} onChange={setSubsubject} data={subsubjectList} placeholder="Selecione um Subassunto" style={{ width: "100%" }} size="lg"
                            onClean={() => {
                                setSubsubject('');
                                setSubject('');
                            }}>
                                
                            </Select>
                        </Row>
                        <Row className="mb-4">
                            <h4>Observação</h4>
                            <Input placeholder="Digite uma observação" id="obs-classification" as="textarea" value={obs} rows={7} onChange={setObs}></Input>
                        </Row>
                        <Row className='d-flex justify-content-end'>
                            <Button onClick={handleClassification} color="primary" className="bottom-0 end-0 right-0 mb-4">Atualizar</Button>
                        </Row>
                    </Drawer.Body>
                </Drawer>


            <Modal open={loadingLote} backdrop={false} size={150}>
                <Loader content="Cadastrando processos" vertical />
            </Modal>
        </Container>
        </>
    )
    
}