import React from "react";
import imgNF from "../../assets/img/brand/404.png";

import { NavLink } from "react-router-dom";

export default function NotFound() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <img src={imgNF} style={{ width: '40%' }} alt="Denied" />
            <h3>Ops! A página que você tentou acessar não existe! Voltar para o <NavLink to="/dashboard" >dashboard</NavLink></h3>
        </div>
    );
}