import axios from "axios";
import { getTokenDecript } from "../auth/jwtUtils";

const BASEURL = process.env.REACT_APP_BASE_URL;
const SUBJECT_URL = process.env.REACT_APP_ENDPOINT_SUBJECTS
const TOKEN = process.env.REACT_APP_APPLICATION_TOKEN;
const USER_TOKEN = process.env.REACT_APP_TOKEN

export async function getSubjectList(activePage, limit, bool) {

  var finalQuery = '';
  if(bool){
    finalQuery = '?page='+activePage+'&page_size='+limit
  }

  try {
    const response = await axios.get(BASEURL+SUBJECT_URL+finalQuery, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function getSubjectParams(params, activePage, limit, bool) {

  var finalQuery = '';
  if(bool){
    finalQuery = '&page='+activePage+'&page_size='+limit
  }

  try {
    const response = await axios.get(BASEURL+SUBJECT_URL+'?name='+params.name+'&description='+params.description+finalQuery, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function saveSubject(params) {
  const transformedJson = JSON.stringify(params)
  try {
    const response = await axios.post(BASEURL+SUBJECT_URL, transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 201) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function updateSubject(id, data){
  const transformedJson = JSON.stringify(data)
  try {
    const response = await axios.put(BASEURL+SUBJECT_URL+id+'/', transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function deleteSubject(id){
  try {
    const response = await axios.delete(BASEURL+SUBJECT_URL+id, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 204) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}
