import axios from "axios";
import { getTokenDecript } from "../auth/jwtUtils";


const BASEURL = process.env.REACT_APP_BASE_URL;
const GROUPS_URL = process.env.REACT_APP_ENDPOINT_GROUPS
const TOKEN = process.env.REACT_APP_APPLICATION_TOKEN;
const USER_TOKEN = process.env.REACT_APP_TOKEN


export async function getGroups() {
    try {
        const response = await axios.get(BASEURL+GROUPS_URL, {
          headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+getTokenDecript(),
          'Application-Token': TOKEN,
        }})
        if(response.status === 200) {
          return response;
        }
        console.log(response)
      }catch(error) {
        console.log(error)
        return error
      }
}

export async function createGroup(data) {
  const transformedData = JSON.stringify(data)
  console.timeLog(data)
  try {
      const response = await axios.post(BASEURL+GROUPS_URL, transformedData, {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+getTokenDecript(),
        'Application-Token': TOKEN,
        }})
        if(response.status === 201) {
            return response;
        }
        }
        catch(error) {
            console.log(error)
            return error
        }
}

export async function deleteGroup(id) {
    try {
        const response = await axios.delete(BASEURL+GROUPS_URL+id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+getTokenDecript(),
            'Application-Token': TOKEN,
        }})
        console.log("resposta"+response)
        if(response.status === 204) {
            return response;
        }
        console.log(response)
}catch(error) {
        console.log(error)
        return error
    }
}

export async function updateGroup(id, data) {
    const transformedData = JSON.stringify(data)
    try {
        const response = await axios.put(BASEURL+GROUPS_URL+id+'/', transformedData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+getTokenDecript(),
            'Application-Token': TOKEN,
        }})
        if(response.status === 200) {
            return response;
        }
        console.log(response)
    }catch(error) {
        console.log(error)
        return error
    }
}
