import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "assets/css/style.css"

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { StrictMode, useEffect, useState } from "react";
import { getInfosByToken, getTokenDecript, isValidToken } from "auth/jwtUtils";

import AdminLayout from "layouts/Admin.js";
import { Circles } from "react-loader-spinner";
import CryptoJS from "crypto-js";
import ReactDOM from "react-dom/client";
import { getUserRoles } from "services/userService";
import pgeimg from 'assets/img/brand/lineu-logo.jpeg'
import { ReactComponent as PgeIcon } from 'assets/img/brand/lineu-logo.svg'

//redux
import { Provider } from 'react-redux'
import store from './redux/store'

// layouts
import AccountError from "layouts/AccountError";
import Greetings from "layouts/Greetings";

import { useNavigate } from "react-router-dom";
import ProtectedRoute from "auth/protectedRoute";
import RolesSync from "auth/rolesSync";

const App = () => {

  const apptoken = process.env.REACT_APP_APPLICATION_TOKEN;
  const internalCaipora = process.env.REACT_APP_INTERNAL_HOME;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let userRoles = [];

      const caminho = new URLSearchParams(window.location.search);
      const jwt = caminho.get('jwt');
      const refreshToken = caminho.get('refresh_token');
      const tokenType = caminho.get('tokenType');

      if(jwt != null && refreshToken != null && tokenType != null){
        if(!isValidToken(jwt)){
          window.location.href = internalCaipora
          return;
        }
        else{
          const fetchRoles = async () => {
            const response = await getUserRoles(getInfosByToken(jwt).sub, jwt);
            return response;
          }
  
          const response = fetchRoles().then((res) => {
            
            try{
              if(res.status != 200){
                localStorage.removeItem('raccoon');
                return;
              }
              let allPermissions = [];
              
              //mudança de estrutura
              let allgroups = res.data.results[0].groups

              let additionalInfos = {
                id: res.data.results[0].id,
                username: res.data.results[0].username,
                email: res.data.results[0].email,
                firstName: res.data.results[0].first_name,
                lastName: res.data.results[0].last_name,
                isStaff: res.data.results[0].is_staff,
                isActive: res.data.results[0].is_active,
                category: res.data.results[0].profile.category,
              }
              
              for (let i = 0; i < allgroups.length; i++) {
                allPermissions = allPermissions.concat(allgroups[i].permissions);
              }
  
              //uniq codename
              for (let i = 0; i < allPermissions.length; i++) {
                if(!userRoles.includes(allPermissions[i].codename)){
                  userRoles.push(allPermissions[i].codename);
                }
              }
  
              const key = CryptoJS.SHA256(apptoken).toString()
              const encrypted = CryptoJS.AES.encrypt(JSON.stringify( {jwt: jwt, roles: userRoles, infos: additionalInfos} ), key).toString();
  
              localStorage.setItem('raccoon', encrypted);
              setLoading(false);
              return;
            }catch(e){
              window.location.href = internalCaipora
              return;
            }
          });  
        } 
      }else{
        if(localStorage.getItem('raccoon') != null){
          const jwtRac = getTokenDecript();
          if(!isValidToken(jwtRac)){
            localStorage.removeItem('raccoon');
            window.location.href = internalCaipora
            return;
          }
          setLoading(false);
          return;
        }else{
          window.location.href = internalCaipora
          return;
        }
      }
  }, []);

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column', }}>
         
          {/* use svg */}

          <img src={pgeimg} style={{ marginBottom: 25, height: '80px'  }} alt="pge" />
          {/* <PgeIcon style={{ marginBottom: 25 }} /> */}
          <Circles
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            />
        </div>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={  
              // <ProtectedRoute permissionsAccepted={[]} mode="component" >
                <AdminLayout />
              // </ProtectedRoute>
              } />
            <Route path="/account-error" element={<AccountError />} />
            
            <Route path="/home" element={
              <RolesSync>
              <Greetings />
              </RolesSync>
              } />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
      <App /> 
    </Provider>
);
