import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import nodata from '../../assets/img/icons/common/no-data-icon.svg'

export function CustomLoadingOverlay() {
    return (
        <CircularProgress />
    );
  }

export function LostConnectionOverlay() {
    return (
        < >
            <WifiOffIcon fontSize="large" />
            <Box sx={{ mt: 1 }}><Typography variant='subtitle' fontFamily="Segoe UI" color="red" fontSize="1.2rem">Conexão perdida</Typography></Box>
        </>
    );
  }

export function CustomNoRowsOverlay() {
    return (
        <>
        {/* <img src={nodata} alt="No data" style={{ width: '100px' }}/> */}
        <Box sx={{ mt: 1, ml:-2 }}>Sem dados</Box>
        </>
    );
  }