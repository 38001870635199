import axios from "axios";
import { getTokenDecript } from "../auth/jwtUtils";

const PERMISSIONS_URL = process.env.REACT_APP_ENDPOINT_PERMISSIONS;
const BASEURL = process.env.REACT_APP_BASE_URL;
const TOKEN = process.env.REACT_APP_APPLICATION_TOKEN;


export const getPermissionsByContentType = async (contentType) => {
    try {
      const response = await axios.get(`${BASEURL}${PERMISSIONS_URL}`, {
        params: { content_type_id: contentType },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getTokenDecript()}`,
          'Application-Token': TOKEN,
        }
      });
      return response;
    } catch (error) {
      console.error('Error fetching permissions:', error);
      throw error;
    }
  };