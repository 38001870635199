/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import 'rsuite/Placeholder/styles/index.css';

// reactstrap components
import { Card, CardBody, CardTitle, Col, Container, Nav, Row } from "reactstrap";
import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";

import { Placeholder } from 'rsuite';
import ProtectedRoute from 'auth/protectedRoute';

const Header = (props) => {

  const [process, setProcess] = useState([]);
  const [isLostConnection, setIsLostConnection] = useState(true);
  const [myUser, setMyUser] = useState();
  const [mostClassifier, setMostClassifier] = useState();

  React.useState(() => {
    setProcess(props.processes);
    setIsLostConnection(props.lostConnection);
  })

  const lastCreatedProcess = process.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  })[0];

  useEffect(() => {
    const classifiedProcess = process.filter((p) => p.classification_date !== null && p.classifier_user !== null);
    const classifiers = classifiedProcess.map((p) => p.classifier_user?.id);
    const mostClassifier = classifiers.sort((a, b) =>
      classifiers.filter((v) => v === a).length - classifiers.filter((v) => v === b).length
    ).pop();
    const user = classifiedProcess.find((p) => p.classifier_user?.id === mostClassifier);
    setMostClassifier(user?.classifier_user);
    console.log("most",user?.classifier_user); 
  }, []);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                      <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Nº de processos
                        </CardTitle>
                        <span className="div font-weight-bold mb-0">
                          {isLostConnection ? <Placeholder.Paragraph rows={1} /> : process.length}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i className="fas fa-file" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Sem classificação
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{
                        isLostConnection ? <Placeholder.Paragraph rows={1} /> : process.filter((p) => p.subsubject == null).length
                        }</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-file-circle-exclamation" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Desde o último mês</span>
                    </p> */}
                  </CardBody>
                </Card>
              </Col>
              <ProtectedRoute permissionsAccepted={['view_process']} mode={'component'}>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Submetidos hoje
                          </CardTitle>
                          <span className="div font-weight-bold mb-0">{
                          isLostConnection ? <Placeholder.Paragraph rows={1} /> :
                            process.filter(
                              (p) =>
                                new Date(p.classification_date).toISOString().split("T")[0] === new Date().toISOString().split("T")[0]
                            ).length
                          }</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-calendar-day" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2">
                          <i className="fas fa-arrow-down" /> 1.10%
                        </span>{" "}
                        <span className="text-nowrap">Desde ontem</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
              </ProtectedRoute>
              <ProtectedRoute permissionsAccepted={['view_all_process']} mode={'component'}>
                <Col lg="6" xl="3">
                  <Card className='card-stats mb-4 mb-xl-0'>
                    <CardBody>
                      <Row>
                        <div className='col'>
                          <CardTitle
                            tag='h5'
                            className='text-uppercase text-muted mb-0'
                          >
                            Último criado
                          </CardTitle>
                          <span className='div font-weight-bold mb-0'>{
                          isLostConnection ? <Placeholder.Paragraph rows={1} /> :
                            lastCreatedProcess ? 
                            <NavLink to={`/gerenciar/processo/${lastCreatedProcess.number}`}>
                              <span style={{ fontSize: 'small'}}>
                              {lastCreatedProcess.number}</span>
                            </NavLink>
                            : <Placeholder.Paragraph rows={1} width={"100%"} />
                          }</span>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-info text-white rounded-circle shadow'>
                            <i className='fas fa-user-tag' />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </ProtectedRoute>
              <ProtectedRoute permissionsAccepted={['view_process']} mode={'component'}>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Classificados hoje
                          </CardTitle>
                          <span className="div font-weight-bold mb-0">{
                          isLostConnection ? <Placeholder.Paragraph rows={1} /> :
                          process.filter(
                            (p) =>
                              new Date(p.classification_date).toISOString().split("T")[0] === new Date().toISOString().split("T")[0]
                          ).length
                          }</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-user-tag" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{" "}
                        <span className="text-nowrap">Da média</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
              </ProtectedRoute>
              <ProtectedRoute permissionsAccepted={['view_all_process']} mode={'component'}>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Maior classificador
                          </CardTitle>
                          <span className="div font-weight-bold mb-0">{
                          mostClassifier ? 
                          <NavLink to={`/painel-de-controle/usuarios/${mostClassifier?.id}`} style={{ fontSize: 'small'}}>{mostClassifier?.username}</NavLink> :
                          <Placeholder.Paragraph rows={1} width={"100%"} />
                          }</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-user-tag" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{" "}
                        <span className="text-nowrap">Da média</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
              </ProtectedRoute>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
