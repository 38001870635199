import React from "react";


import pgeimg from 'assets/img/brand/lineu-logo.jpeg'
import { getAdditionalInfoDescript } from "auth/jwtUtils";
import { useEffect } from 'react';
import { getRolesDescript } from "auth/jwtUtils";

export default function AccountError() {


    // useEffect(() => {
    //     document.title = "Boas vindas"
    //     if(getRolesDescript().length > 0){
    //         window.location.href = "/dashboard"
    //     }
    // })

    return( 
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column', }}>
          <img src={pgeimg} style={{ marginBottom: 25, height: '80px'  }} alt="pge" />
          
          <h5>Olá, { getAdditionalInfoDescript()?.firstName + " " + getAdditionalInfoDescript()?.lastName }. Para começar a usar o sistema solicite permissões ao administrador.</h5>
        </div>
    )
}