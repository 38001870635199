import { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Label,
    Row,
    Table
} from "reactstrap";

import { Message, Divider, Button, Tabs, Panel, Modal, useToaster, Input, Whisper, Tooltip } from 'rsuite';

import { NavLink } from "react-router-dom";

import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';

import { getRequests, deleteRequestById } from "services/requestService"; 
import 'rsuite/Tabs/styles/index.css';
import './notifications.css'
import { Height } from "@mui/icons-material";
import { rejectRequest } from "services/requestService";
import { MessageBox } from "components/Utils/MessageBox";
import { approveRequest } from "services/requestService";
import { setStatusRequest } from "services/requestService";
import { getAdditionalInfoDescript, hasRole } from "auth/jwtUtils";
import ProtectedRoute from "auth/protectedRoute";

export default function Notifications() {

    const toaster = useToaster();

    const STATUS = {
        'pendente': 'orange',
        'aprovado': 'green',
        'não aprovado': 'red',
    };

    const [notificationNumber, setNotificationNumber] = useState(0);
    const [requests, setRequests] = useState([]);
    const [activeKey, setActiveKey] = useState('1');
    const [atualItem, setAtualItem] = useState();


    // modais
    const [modelReject, setModelReject] = useState(false);
    const [modelAccept, setModelAccept] = useState(false);
    const [rejectComment, setRejectComment] = useState("");
    const [acceptComment, setAcceptComment] = useState("");

    const handleRejectClose = () => setModelReject(false);
    const handleAcceptClose = () => setModelAccept(false);

    const [modalcancelreport, setModalCancelReport] = useState(false);
    const [atualRequestId, setAtualRequestId] = useState();

    const handleModalClose = () => setModalCancelReport(false);



    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);

    // busca as solicitações
    useEffect(() => {
        async function fetchData() {
            let response = []
            if( hasRole('change_changecategoryrequest')){
                response = await getRequests("", "", "", "", "");
            }else{
                response = await getRequests("", '', getAdditionalInfoDescript().id, '', '');
            }
            if (response) {
                setRequests(response.data.results);
                setNotificationNumber(response.length);
            }else{
                Message.error('Erro ao buscar as solicitações');
            }
        }
        fetchData();
    }, [modelReject, modalcancelreport]);

    // rejeita a solicitação
    const handleRejectRequest = async () => {
        try{
            const response = await setStatusRequest(atualItem, "NÃO APROVADO", rejectComment);
            if (response.status == 200) {
                toaster.push(MessageBox('success', "Sucesso", 'A solicitação foi rejeitada'));
                setModelReject(false);

            } else {
                toaster.push(MessageBox('error', "Erro", 'Não foi possível rejeitar a solicitação'));
            }
        }catch(error){
            toaster.push(MessageBox('error', "Erro", 'Não foi possível rejeitar a solicitação'));
        }
        
        setModelReject(false);
    }
  
    // aceita a solicitação
    const handleAcceptRequest = async () => {
        try{
            const response = await approveRequest(atualItem);
            if (response.status == 200) {
                toaster.push(MessageBox('success', "Sucesso", 'A solicitação foi aceita'));
                setModelAccept(false);

            } else {
                toaster.push(MessageBox('error', "Erro", 'Não foi possível aceitar a solicitação'));
            }
        }catch(error){
            toaster.push(MessageBox('error', "Erro", 'Não foi possível aceitar a solicitação'));
        }
        
        setModelAccept(false);
    }

    // cancela a solicitação
    const handleCancelRequest = async () => {
        const fetch = async () => {
            try {
                const resultado = await deleteRequestById(atualRequestId);
                console.log(resultado)
                if(resultado.status === 204){
                    toaster.push(MessageBox("success", "Sucesso", "Solicitação cancelada"), {duration: 5000 })
                    setModalCancelReport(false);
                }else{
                    toaster.push(MessageBox("error", "Erro", "Ocorreu um erro em sua solicitação"), {duration: 5000 })
                }
            } catch (error) {
                console.error('Erro ao buscar dados assíncronos:', error);
            }
        }
        fetch();
    }

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8 opacity-8">
                <Container fluid>
                </Container>
            </div>

            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row>
                                    <Col>
                                        <h3 className="mb-0">Notificações</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    {/* <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                                            <h3 style={{ display: 'flex', justifyContent: 'center' }}>
                                                01/01/01
                                            </h3>
                                            <Divider style={{ width: '100%' }}></Divider>
                                        </div>

                                        <Message style={{ width: "80%", display: 'flex', flexDirection: 'column' }}>
                                            <ul style={{ listStyleType: 'none' }}>
                                                <li>
                                                    <strong>Usuário: </strong> user@gmail.com
                                                </li>
                                                <li>
                                                    <strong>Solicitação: </strong> erro no processo
                                                </li>
                                                <li>
                                                    <strong>Justificativa: </strong> erro no processo
                                                </li>
                                            </ul>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button color="green" appearance="primary" className="mr-2">
                                                    <CheckIcon color="white" />
                                                </Button>
                                                <Button color="red" appearance="primary">
                                                    <CloseIcon color="white" />
                                                </Button>
                                            </div>
                                        </Message>
                                    </Col> */}
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <Tabs activeKey={activeKey} onSelect={setActiveKey} style={{ width: '100%' }} >
                                            <Tabs.Tab eventKey="1" title="Solicitações" style={{ minHeight: '300px' }}>
                                                {
                                                    <Table responsive >
                                                        <thead>
                                                            <tr>
                                                                <th>Tipo</th>
                                                                <th>Usuário</th>
                                                                <th>Processo</th>
                                                                <th>Núcleo sugerido</th>
                                                                <th>Justificativa</th>
                                                                <th>Status</th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                            
                                                            requests.find(
                                                                (request) => request.status == "PENDENTE"
                                                            ) == null ? 
                                                            
                                                            <tr><td colSpan="6" className="text-center">Nenhuma solicitação</td></tr> :
                                                            
                                                            requests.map((request) => (
                                                                request.status == "PENDENTE" &&
                                                                <tr key={request.id}>
                                                                    <td>
                                                                        {
                                                                            request.process == null ? <h5>Mudança de núcleo do usuário</h5>
                                                                            : <h5>Mudança de núcleo do processo</h5>
                                                                        }
                                                                    </td>
                                                                    <NavLink to={"/painel-de-controle/usuarios/" + request.classifier_user.id}>
                                                                        <td>{request.classifier_user.email + " - " + request.classifier_user.first_name + " " + request.classifier_user.last_name}</td>
                                                                    </NavLink>
                                                                    <td>{request.process == null ? "" : 
                                                                    <NavLink to={"/gerenciar/processo/" + request.process.number}>
                                                                        {request.process.number}
                                                                    </NavLink>
                                                                    }</td>
                                                                    <td>{request.category.name}</td>
                                                                    <td>{request.justify}</td>
                                                                    <td>
                                                                        <i class="fa-solid fa-circle fa-sm mr-2"  style={{ color: "orange" }}></i>{request.status}
                                                                    </td>
                                                                    <td>
                                                                        <ProtectedRoute permissionsAccepted={['change_changecategoryrequest']} mode={'component'}
                                                                            alternative={
                                                                                <Whisper placement="top" trigger="hover" speaker={<Tooltip>Cancelar solicitação</Tooltip>}>
                                                                                    <Button style={{ backgroundColor: '#F87171', color: 'white' }} className='mr-2' size="sm" onClick={() =>{
                                                                                        setModalCancelReport(true)
                                                                                        setAtualRequestId(request.id)
                                                                                    }}>
                                                                                        <i className="fa-solid fa-xmark"></i>
                                                                                    </Button>
                                                                                </Whisper>
                                                                            }
                                                                        >
                                                                            <Whisper placement="top" trigger="hover" speaker={<Tooltip>Rejeitar solicitação</Tooltip>}>
                                                                                <Button color="red" appearance="primary" className="mr-2"
                                                                                    onClick={() => {
                                                                                        setModelReject(true);
                                                                                        setAtualItem(request.id);
                                                                                    }}
                                                                                >
                                                                                    <CloseIcon color="white" />
                                                                                </Button>
                                                                            </Whisper>
                                                                            <Whisper placement="top" trigger="hover" speaker={<Tooltip>Aprovar solicitação</Tooltip>}>
                                                                                <Button color="green" appearance="primary"
                                                                                    onClick={() => {
                                                                                        setModelAccept(true);
                                                                                        setAtualItem(request.id);
                                                                                    }}
                                                                                >
                                                                                    <CheckIcon color="white" />
                                                                                </Button>
                                                                            </Whisper>
                                                                        </ProtectedRoute>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                }
                                            </Tabs.Tab>
                                            <Tabs.Tab eventKey="2" title="Histórico">
                                                {
                                                    <Table responsive >
                                                        <thead>
                                                            <tr>
                                                                <th>Tipo</th>
                                                                <th>Usuário</th>
                                                                <th>Processo</th>
                                                                <th>Núcleo sugerido</th>
                                                                <th>Justificativa</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                            requests.find(
                                                                (request) => request.status != "PENDENTE"
                                                            ) == null ? 

                                                            <tr><td colSpan="6" className="text-center">Nenhum registro</td></tr> :
                                                            
                                                            requests.map((request) => (
                                                                request.status != "PENDENTE" &&
                                                                <tr key={request.id}>
                                                                    <td>
                                                                        {
                                                                            request.process == null ? <h5>Mudança de núcleo do usuário</h5>
                                                                            : <h5>Mudança de núcleo do processo</h5>
                                                                        }
                                                                    </td>
                                                                    <NavLink to={"/painel-de-controle/usuarios/" + request.classifier_user.id}>
                                                                        <td>{request.classifier_user.email + " - " + request.classifier_user.first_name + " " + request.classifier_user.last_name}</td>
                                                                    </NavLink>
                                                                    <td>{request.process == null ? "" : 
                                                                    <NavLink to={"/gerenciar/processo/" + request.process.number}>
                                                                        {request.process.number}
                                                                    </NavLink>
                                                                    }</td>
                                                                    <td>{request.category.name}</td>
                                                                    <td>{request.justify}</td>
                                                                    <td>
                                                                        <i class="fa-solid fa-circle fa-sm mr-2"  style={{ color: STATUS[request.status.toLowerCase()] }}></i>{request.status}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                }
                                            </Tabs.Tab>
                                        </Tabs>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>

            <Modal open={modelReject} onClose={handleRejectClose}>
                <Modal.Header>
                <Modal.Title>Cancelar solicitação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Label>Comentário</Label>
                        <Input as="textarea" rows={4} value={rejectComment} onChange={setRejectComment} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button size="sm" onClick={() => setModelReject(false)} appearance="subtle">
                    Cancelar
                </Button>
                <Button size="sm" style={{ backgroundColor: '#f87171' }} onClick={handleRejectRequest} appearance="primary">
                    Confirmar
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal open={modelAccept} onClose={handleAcceptClose}>
                <Modal.Header>
                <Modal.Title>Aprovar solicitação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Label>Comentário</Label>
                        <Input as="textarea" rows={4} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button size="sm" onClick={() => setModelAccept(false)} appearance="subtle">
                    Cancelar
                </Button>
                <Button size="sm" onClick={handleAcceptRequest} appearance="primary">
                    Confirmar
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal open={modalcancelreport} onClose={handleModalClose}>
                <Modal.Header>
                <Modal.Title>Cancelar solicitação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja realmente cancelar a solicitação? Essa ação é irreversível.
                </Modal.Body>
                <Modal.Footer>
                <Button size="sm" onClick={() => setModalCancelReport(false)} appearance="subtle">
                    Cancelar
                </Button>
                <Button size="sm" style={{ backgroundColor: '#f87171' }} onClick={handleCancelRequest} appearance="primary">
                    Confirmar
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )};
