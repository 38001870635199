import { Message } from 'rsuite'


//mensagens de erro e sucesso
const messagesContent = {
    common: {
        alertFields: "Verifique se os campos estão preenchidos corretamente."
    },
    groups: {
        messageDeleteError: "Não foi possível deletar o grupo.",
        messageDeleteSuccess: "Grupo deletado com sucesso.",
        messageUpdateError: "Não foi possível atualizar o grupo.",
        messageUpdateSuccess: "Grupo atualizado com sucesso.",
        messageCreateError: "Não foi possível criar o grupo.",
        messageCreateSuccess: "Grupo criado com sucesso.",
    },
    user: {
        messageUpdateSuccess: "Usuário atualizado com sucesso.",
        messageUpdateError: "Não foi possível atualizar o usuário.",
    },
    category: {
        messageError: "Não foi possível criar o Núcleo.",
        messageUpdateError: "Não foi possível atualizar o Núcleo.",
        messageUpdateSuccess: "Núcleo atualizado com sucesso.",
        errorDeleteAlert: "Não foi possível deletar o Núcleo.",
        successDeleteAlert: "Núcleo deletado com sucesso.",
        messageSuccess: "Núcleo criado com sucesso.",
    },
    process: {
        messageUpdateError: "Não foi possível atualizar o processo.",
        messageUpdateSuccess: "Processo atualizado com sucesso.",
        messageError: "Não foi possível criar o processo.",
        errorDeleteAlert: "Não foi possível deletar o processo.",
        successDeleteAlert: "Processo deletado com sucesso.",
        messageSuccess: "Processo criado com sucesso.",
    },
    subject: {
        errorLinkedSubsubject: "Não foi possível deletar o Assunto, pois existem subassuntos/processos vinculados a ele.",
        messageError: "Não foi possível criar o Assunto.",
        messageUpdateError: "Não foi possível atualizar o Assunto.",
        messageUpdateSuccess: "Assunto atualizado com sucesso.",
        errorDeleteAlert: "Não foi possível deletar o Assunto.",
        successDeleteAlert: "Assunto deletado com sucesso.",
        messageSuccess: "Assunto criado com sucesso.",
    },
    subsubject: {
        messageError: "Não foi possível criar o subassunto.",
        messageUpdateError: "Não foi possível atualizar o subassunto.",
        messageUpdateSuccess: "Subassunto atualizado com sucesso.",
        errorDeleteAlert: "Não foi possível deletar o subassunto.",
        successDeleteAlert: "Subassunto deletado com sucesso.",
        messageSuccess: "Subassunto criado com sucesso.",
    }
}


//função para exibir a mensagem
function MessageBox(messageType, messageTitle, messageContent) {
    return (
        <Message showIcon type={messageType} closable>
            <strong>{messageTitle}!</strong> {messageContent}
        </Message>
    )
}

export { MessageBox, messagesContent }