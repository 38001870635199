import axios from "axios";
import { getTokenDecript } from "../auth/jwtUtils";

const USER_URL = process.env.REACT_APP_ENDPOINT_USERS;
const BASEURL = process.env.REACT_APP_BASE_URL;
const TOKEN = process.env.REACT_APP_APPLICATION_TOKEN;

export async function getUsersList(){
    try {
      const response = await axios.get(BASEURL+USER_URL, {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+getTokenDecript(),
        'Application-Token': TOKEN,
        }})
         if(response.status === 200) {
            return response;
        }
    }catch(error) {
        console.log(error)
        return error
    }
}

export async function getUser(id){
    try {
      const response = await axios.get(BASEURL+USER_URL+id+'/', {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+getTokenDecript(),
        'Application-Token': TOKEN,
        }})
         if(response.status === 200) {
            return response;
        }
    }
    catch(error) {
        console.log(error)
        return error
    }
}

export async function getUserRoles(email, jwt){

    try {
      const response = await axios.get(BASEURL+USER_URL+'?email='+email, {
        headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+jwt,
        'Application-Token': TOKEN,
        }})
         if(response.status === 200) {
            return response;
        }
    }catch(error) {
        console.log(error)
        return error
    }
}

export async function updateUser(id, data){
    const transformedJson = JSON.stringify(data)
    try {
        const response = await axios.put(BASEURL+USER_URL+id+'/', transformedJson, {
          headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+getTokenDecript(),
          'Application-Token': TOKEN,
          }})
           if(response.status === 200) {
              return response;
          }
          console.log(response)
      }catch(error) {
          console.log(error)
          return error
      }
}