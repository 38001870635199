// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-toggler {
    padding: 0;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    margin-right: 1rem;
}
.sidebar-toggler:hover,
.sidebar-toggler:focus {
    text-decoration: none;
}

@media (max-width: 768px) {
    .sidebar-toggler {
        display: none;
    }
}

@media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md .navbar-brand {
        padding-bottom: 0;
    }
}

.navbar-vertical.collapsed {
    width: 80px;
    transition: width 100ms ease;
}

.navbar-vertical.collapsed .nav-link-text{
    display: none;
}

.main-content {
    transition: margin-left 100ms ease;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,cAAc;IACd,6BAA6B;IAC7B,6BAA6B;IAC7B,uBAAuB;IACvB,kBAAkB;AACtB;AACA;;IAEI,qBAAqB;AACzB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI,WAAW;IACX,4BAA4B;AAChC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".sidebar-toggler {\r\n    padding: 0;\r\n    font-size: 1.25rem;\r\n    line-height: 1;\r\n    background-color: transparent;\r\n    border: 1px solid transparent;\r\n    border-radius: 0.375rem;\r\n    margin-right: 1rem;\r\n}\r\n.sidebar-toggler:hover,\r\n.sidebar-toggler:focus {\r\n    text-decoration: none;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .sidebar-toggler {\r\n        display: none;\r\n    }\r\n}\r\n\r\n@media (min-width: 768px) {\r\n    .navbar-vertical.navbar-expand-md .navbar-brand {\r\n        padding-bottom: 0;\r\n    }\r\n}\r\n\r\n.navbar-vertical.collapsed {\r\n    width: 80px;\r\n    transition: width 100ms ease;\r\n}\r\n\r\n.navbar-vertical.collapsed .nav-link-text{\r\n    display: none;\r\n}\r\n\r\n.main-content {\r\n    transition: margin-left 100ms ease;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
