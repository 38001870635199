// react plugin used to create charts
import { Bar, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { CustomLoadingOverlay, LostConnectionOverlay } from "../components/Utils/nodata";
import React, { useEffect, useState } from "react";
// core components
import {
  chartExample1,
  chartExample2,
  stackedBar,
  chartOptions,
  parseOptions,
} from "variables/charts.js";

// javascipt plugin for creating charts
import Chart from "chart.js";
import Header from "components/Headers/Header.js";
/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import { getProcessList } from "services/processService.js";
import { getProcessParams } from "services/processService";
import { getAdditionalInfoDescript } from "auth/jwtUtils";
import { hasRole } from "auth/jwtUtils";
import ProtectedRoute from "auth/protectedRoute";

const Index = (props) => {


  const [activeNav, setActiveNav] = useState(0);
  const [chartExample1Data, setChartExample1Data] = useState(["assunto", "subassunto", "nucleo"]);

  const [chartIndex, setChartIndex] = useState(0);
  
  const [processData, setProcessData] = useState([]);
  const [keyChild, setKeyChild] = useState(0);
  const [pieAssunto, setPieAssunto] = useState({});
  const [pieSubAssunto, setPieSubAssunto] = useState({});
  const [pieNucleo, setPieNucleo] = useState({});
  const [isLostConnection, setIsLostConnection] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [user, setUser] = useState(getAdditionalInfoDescript())
  const [processesbyUser, setProcessesbyUser] = useState([])

  const groupProcessByYear = (processes, year) => {
    const processyear = processes.filter((process) => {
      return new Date(process.classification_date).getFullYear() === year;
    });

    // retorna lista de tamanho 12 com a quantidade de processos por mês
    return Array.from({ length: 12 }, (_, i) => {
      return processyear.filter((process) => {
        return new Date(process.classification_date).getMonth() === i;
      }).length;
    });

  }

  useEffect(() => {

    let processes = processData;
    // preciso dos onze primeiro usuários que mais tem processos
    // cada posicao do array é um objeto {classificados: num, nao_classificados: num}
    processes = processes.filter((process) => process?.classifier_user !== null);
    const users = processes.reduce((acc, process) => {
      const user = process?.classifier_user.id;
      if (acc[user] === undefined) {
        acc[user] = { classificados: 0, nao_classificados: 0, name: process?.classifier_user.first_name + ' ' + process?.classifier_user.last_name };
      }
      if (process.classification_date !== null) {
        acc[user].classificados++;
      } else {
        acc[user].nao_classificados++;
      }
      return acc;
    }, {});

    console.log(users)

    // por usuario, separa duas listas, uma com os classificados e outra com os nao classificados
    const classified = Object.values(users).map((user) => user.classificados);
    const notClassified = Object.values(users).map((user) => user.nao_classificados);

    // 
    const names = Object.values(users).map((user) => user.name);

    // retorna um objeto, username dos usuarios, lista de classificados e lista de nao classificados
    let result = {
      labels: names,
      datasets: [
        {
          label: "Classificados",
          data: classified,
          backgroundColor: 'blue',
        },
        {
          label: "Não classificados",
          data: notClassified,
          backgroundColor: 'orange',
        },
      ],
    };

    console.log(result);

    setProcessesbyUser(result);

  }, [processData]);


  React.useEffect( () => {
    const subjectCount = {}
    const subSubjectCount = {}
    const nucleoCount = {}

    try{

      processData.forEach(item => {
        var subject;
        var subsubject;
        var nucleo;

        if(item.subject !== null){
          subject = item.subject.name;
        }else{
          subject = "Sem assunto"
          subjectCount[subject] = 0;
        }
        
        if(item.subsubject !== null){
          subsubject = item.subsubject.name;
        }else{
          subsubject = "Sem subassunto"
          subSubjectCount[subsubject] = 0;
        }

        if(item.category !== null){
          nucleo = item.category.name;
        }else{
          nucleo = "Sem núcleo"
          nucleoCount[nucleo] = 0;

        }
      
        if (subject !== null) {
          if (subjectCount[subject] === undefined) {
            subjectCount[subject] = 1;
          } else {
            subjectCount[subject]++;
          }
        }
      
        if (subsubject !== null) {
          if (subSubjectCount[subsubject] === undefined) {
            subSubjectCount[subsubject] = 1;
          } else {
            subSubjectCount[subsubject]++;
          }
        }

        if (nucleo !== null) {
          if (nucleoCount[nucleo] === undefined) {
            nucleoCount[nucleo] = 1;
          } else {
            nucleoCount[nucleo]++;
          }
        }
        
      });

      const labels = Object.keys(subjectCount);
      const labelsSub = Object.keys(subSubjectCount);
      const quantidades = Object.values(subjectCount);
      const quantidadesSub = Object.values(subSubjectCount);
      const nucleoLabels = Object.keys(nucleoCount);
      const nucleoQuantidades = Object.values(nucleoCount);

      const palette = [
        "#0B3D91",
        "#2E2E2E",
        "#F4F4F4",
        "#6D001A",
        "#5A769A",
        "#1C1C1C",
        "#9E9E9E",
        "#2C5364",
        "#EFEFEF",
        "#4B0082"
    ]

      
      setPieAssunto({
        labels: Object.keys(subjectCount),
        datasets: [
          {
            data: quantidades,
            backgroundColor: palette
          },
        ],
      });

      setPieSubAssunto({
        labels: labelsSub,
        datasets: [
          {
            data: quantidadesSub,
            backgroundColor: palette
          },
        ],
      });

      setPieNucleo({
        labels: nucleoLabels,
        datasets: [
          {
            data: nucleoQuantidades,
            backgroundColor: palette
          },
        ],
      });


    } catch (error) {
      console.log(error)
    }

  }, [processData]);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setChartExample1Data("data" + index);
  };

  useEffect(() => {
    setIsLoadingData(true)
    const fetchData = async () => {
      try {
        let resultado = [];
        if(hasRole('view_process')){
          resultado = await getProcessParams('','','', '', user.id, '', 1, 100000, true);
        }else if(hasRole('view_all_process')){
          resultado = await getProcessList(1, 100000, true);
        }

        if(resultado.status != 200){
          setIsLoadingData(false);
          setIsLostConnection(true);
          setKeyChild(keyChild + 1);
        }else{
          setIsLoadingData(false);
          setProcessData(resultado.data.results);
          setKeyChild(keyChild + 1);
        } 
        
      } catch (error) {
        console.error('Erro ao buscar dados assíncronos:', error);
      }
    };

    fetchData();
  }, []); 


  return (
    <>
      <Header key={keyChild} processes={processData} lostConnection={isLostConnection}/>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow" >
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Processos
                    </h6>
                    <h2 className="mb-0">{new Date().getFullYear()}</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                {!isLoadingData ? (
                  isLostConnection ? (
                    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                      {LostConnectionOverlay()}
                    </div>
                  ) : (
                    <>
                      <ProtectedRoute permissionsAccepted={['view_process']} mode={'component'}>
                        <Bar data={{
                          labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
                          datasets: [
                            {
                              label: "Sales",
                              data: groupProcessByYear(processData, new Date().getFullYear()),
                              maxBarThickness: 10,
                            },
                          ],
                        }} options={chartExample2.options} />
                      </ProtectedRoute>
                      <ProtectedRoute permissionsAccepted={['view_all_process']} mode={'component'}>
                        <Bar data={
                          {
                            labels: processesbyUser.labels,
                            datasets: [
                              
                              {
                                label: "Sem classificação",
                                data: processesbyUser.datasets[1].data,
                                maxBarThickness: 10,
                              },
                              {
                                label: "Classificados",
                                data: processesbyUser.datasets[0].data,
                                maxBarThickness: 10,
                                backgroundColor: '#11cdef'
                              },
                            ],
                          }
                        } options={stackedBar.options} />
                      </ProtectedRoute>
                    </>
                  )
                ) : <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                  {CustomLoadingOverlay()}
                </div>}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="mb-5 mb-xl-0" xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  
                  <div className="col">
                    
                    <Nav className="d-flex justify-content-around" pills>
                      
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-2")}
                          onClick={() => {
                            setActiveNav(activeNav == 0 ? chartExample1Data.length - 1 : activeNav - 1)
                          }}
                        >
                          <span className="d-none d-md-block"><i className="fas fa-arrow-left" /> {activeNav == 0 ? chartExample1Data[chartExample1Data.length-1] : chartExample1Data[activeNav-1]}</span>
                          <span className="d-md-none"><i className="fas fa-arrow-left" /> {activeNav == 0 ? chartExample1Data[chartExample1Data.length-1] : chartExample1Data[activeNav-1]}</span>
                        </NavLink>
                      </NavItem>
                      <div className="d-flex justify-content-center flex-column">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                          Processos por
                        </h6>
                        <h2 className="mb-0">{chartExample1Data[activeNav]}</h2>
                      </div>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-2")}
                          data-toggle="tab"
                          onClick={() => {
                            setActiveNav(activeNav == (chartExample1Data.length -1) ? 0 : activeNav + 1)
                          }}
                        >
                          <span className="d-none d-md-block"><i className="fas fa-arrow-right" /> {activeNav == chartExample1Data.length-1 ?  chartExample1Data[0] : chartExample1Data[activeNav+1]}</span>
                          <span className="d-md-none"><i className="fas fa-arrow-right" /> {activeNav == 0 ? chartExample1Data[chartExample1Data.length-1] : chartExample1Data[activeNav-1]}</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">

                {!isLoadingData ? (
                  isLostConnection ? (
                    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                      {LostConnectionOverlay()}
                    </div>
                  ) : (
                    <Pie
                    data={chartExample1Data[activeNav] == 'assunto' ? pieAssunto : (
                      chartExample1Data[activeNav] == 'subassunto' ?
                      pieSubAssunto : pieNucleo)}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                  )
                ) : <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                  {CustomLoadingOverlay()}
                </div>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
