import React, { useEffect, useState } from 'react';
import { getInfosByToken, getTokenDecript, isValidToken } from "auth/jwtUtils";

import CryptoJS from "crypto-js";
import { getUserRoles, getUser } from "services/userService";

import { useNavigate } from "react-router-dom";
import { destroySessionNoRedirect } from './protectedRoute';

const RolesSync = ({ children }) => {

    const apptoken = process.env.REACT_APP_APPLICATION_TOKEN;

    const navigate = useNavigate();

    const [everCalled, setEverCalled] = useState(false);

    useEffect(() => {
        const raccoon = localStorage.getItem('raccoon');
        let userRoles = [];

        if(raccoon != null){

            const key = CryptoJS.SHA256(apptoken).toString()
            const decrypted = CryptoJS.AES.decrypt(raccoon, key);
            let dec = decrypted.toString(CryptoJS.enc.Utf8)

            try{
                if(!JSON.parse(dec).infos.isActive){
                    navigate("/account-error")
                    // destroySessionNoRedirect();
                }

                var oldRoles = JSON.parse(dec).roles;
                var jwt = JSON.parse(dec).jwt;

                const fetchRoles = async () => {
                    const response = await getUserRoles(getInfosByToken(jwt).sub, jwt);
                    return response;
                }

                const response = fetchRoles().then((res) => {
          
                    try{
                      if(res.status != 200){
                        localStorage.removeItem('raccoon');
                      }

                      if(!res.data.results[0].is_active){
                        localStorage.removeItem('raccoon');
                        navigate("/account-error")
                      }

                      let additionalInfos = {
                        id: res.data.results[0].id,
                        username: res.data.results[0].username,
                        email: res.data.results[0].email,
                        firstName: res.data.results[0].first_name,
                        lastName: res.data.results[0].last_name,
                        isStaff: res.data.results[0].is_staff,
                        isActive: res.data.results[0].is_active,
                        category: res.data.results[0].profile.category,
                      }

                      let allPermissions = [];
                      let allgroups = res.data.results[0].groups
                      for (let i = 0; i < allgroups.length; i++) {
                        allPermissions = allPermissions.concat(allgroups[i].permissions);
                      }
          
                      //uniq codename
                      for (let i = 0; i < allPermissions.length; i++) {
                        if(!userRoles.includes(allPermissions[i].codename)){
                          userRoles.push(allPermissions[i].codename);
                        }
                      }

                      if(JSON.stringify(oldRoles) !== JSON.stringify(userRoles)){
                          const key = CryptoJS.SHA256(apptoken).toString()
                          const encrypted = CryptoJS.AES.encrypt(JSON.stringify( {jwt: jwt, roles: userRoles, infos: additionalInfos} ), key).toString();
                          localStorage.setItem('raccoon', encrypted);
                          window.location.reload();
                      }

                      return;
                    }catch(e){
                      
                    }
                  });
            }catch(e){
                console.log(e);
            }
        }

    });
    return children;

}

export default RolesSync;