import React from "react";


import pgeimg from 'assets/img/brand/lineu-logo.jpeg'

export default function AccountError() {
    return( <
        div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column', }}>
          <img src={pgeimg} style={{ marginBottom: 25, height: '80px'  }} alt="pge" />
          
          <h5>Ops! Sua conta foi desabilitada, por favor entre em contato com o suporte.</h5>
        </div>
    )
}