import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js";

const caiporaURL = process.env.REACT_APP_CAIPORA_URL;
const internalCaipora = process.env.REACT_APP_INTERNAL_HOME;

export const hasTokenLocalStorage = () => {
    return localStorage.getItem('token') ? true : false;
}

export const hasValidRequestPath = (location) => {
    const urlParams = new URLSearchParams(location.search);
    const tokenType = urlParams.get('tokenType');
    const token = urlParams.get('jwt');
    const refreshToken = urlParams.get('refreshToken');
    
    try{
        if((tokenType == 'Bearer') && isValidToken(token)){
            return true;
        }else{
            return false;
        }
    }catch(e){
        return false
    }
}

export const isValidToken = (token) => {
    try{
        const decoded = jwtDecode(token);
        const timeSpired = () => {
            const now = new Date();
            const exp = new Date(decoded.exp * 1000);
            if(now > exp){
                return true;
            }else{
                return false;
            }
        }
        if(timeSpired()){
            return false;
        }else{
            return true;
        }
    }catch(e){
        return false
    }
}

export const getInfosByToken = (token) => {
    const decoded = jwtDecode(token);
    let response = {
        sub: decoded.sub,
        ip: decoded.ip,
        enabled: decoded.enabled,
        iss: decoded.iss,
        username: decoded.username,
        setor: decoded.setor

    }
    return response;
}

export const getInfosDecript = () => {
    if(localStorage.getItem('raccoon') != null){
        const apptoken = process.env.REACT_APP_APPLICATION_TOKEN;
        const key = CryptoJS.SHA256(apptoken).toString()
        const decrypted = CryptoJS.AES.decrypt(localStorage.getItem('raccoon'), key);
        let dec = decrypted.toString(CryptoJS.enc.Utf8)
        const decoded = jwtDecode(JSON.parse(dec).jwt);
        let response = {
            sub: decoded.sub,
            ip: decoded.ip,
            enabled: decoded.enabled,
            iss: decoded.iss,
            username: decoded.username,
            setor: decoded.setor
        }
        return response
    }
    return "No user"
}

export const getRolesDescript = () => {
    try{
        if(localStorage.getItem('raccoon') != null){
            const apptoken = process.env.REACT_APP_APPLICATION_TOKEN;
            const key = CryptoJS.SHA256(apptoken).toString()
            const decrypted = CryptoJS.AES.decrypt(localStorage.getItem('raccoon'), key);
            let dec = decrypted.toString(CryptoJS.enc.Utf8)
            return JSON.parse(dec).roles;
        }
        return []
    }catch(e){
        return []
    }
}

// hasRole 
export function hasRole(role){
    try{
        if(localStorage.getItem('raccoon') != null){
            const apptoken = process.env.REACT_APP_APPLICATION_TOKEN;
            const key = CryptoJS.SHA256(apptoken).toString()
            const decrypted = CryptoJS.AES.decrypt(localStorage.getItem('raccoon'), key);
            let dec = decrypted.toString(CryptoJS.enc.Utf8)
            return JSON.parse(dec).roles.includes(role);
        }
        return false
    }catch(e){
        return false
    }
}

export const getAdditionalInfoDescript = () => {
    try{
        if(localStorage.getItem('raccoon') != null){
            const apptoken = process.env.REACT_APP_APPLICATION_TOKEN;
            const key = CryptoJS.SHA256(apptoken).toString()
            const decrypted = CryptoJS.AES.decrypt(localStorage.getItem('raccoon'), key);
            let dec = decrypted.toString(CryptoJS.enc.Utf8)
            return JSON.parse(dec).infos;
        }
        return {}
    }catch(e){
        return {}
    }
}

export const getTokenDecript = () => {
    try{
        if(localStorage.getItem('raccoon') != null){
            const apptoken = process.env.REACT_APP_APPLICATION_TOKEN;
            const key = CryptoJS.SHA256(apptoken).toString()
            const decrypted = CryptoJS.AES.decrypt(localStorage.getItem('raccoon'), key);
            let dec = decrypted.toString(CryptoJS.enc.Utf8)
            return JSON.parse(dec).jwt;
        }
    }catch(e){
        return null;
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('setor');
    window.location.href = internalCaipora;
}