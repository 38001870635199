// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* style for screen width less than 768px */
@media (max-width: 767px) {
  .responsive-style{
    display: none;
  }
  .rs-badge-content{
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Navbars/AdminNavBar.css"],"names":[],"mappings":";AACA,2CAA2C;AAC3C;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;AACF","sourcesContent":["\r\n/* style for screen width less than 768px */\r\n@media (max-width: 767px) {\r\n  .responsive-style{\r\n    display: none;\r\n  }\r\n  .rs-badge-content{\r\n    display: none;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
