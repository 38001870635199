// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.formal-text {
    font-family: Arial, sans-serif; 
    font-size: 16px; 
    color: #525f7f; 
    font-weight: normal; 
    text-align: left; 
  }
  `, "",{"version":3,"sources":["webpack://./src/views/admin/groups.css"],"names":[],"mappings":";AACA;IACI,8BAA8B;IAC9B,eAAe;IACf,cAAc;IACd,mBAAmB;IACnB,gBAAgB;EAClB","sourcesContent":["\r\n.formal-text {\r\n    font-family: Arial, sans-serif; \r\n    font-size: 16px; \r\n    color: #525f7f; \r\n    font-weight: normal; \r\n    text-align: left; \r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
