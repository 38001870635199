import axios from "axios";
import { getTokenDecript } from "../auth/jwtUtils";

const BASEURL = process.env.REACT_APP_BASE_URL;
const DOCUMENTS_URL = process.env.REACT_APP_ENDPOINT_DOCUMENTS;
const TOKEN = process.env.REACT_APP_APPLICATION_TOKEN;

export async function getDocsFromProcess(processNumber) {
    try{
        const response = await axios.get(BASEURL + DOCUMENTS_URL + '?process=' + processNumber,{ 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getTokenDecript(),
                'Application-Token': TOKEN,
              }}
        )
        if(response.status == 200){
            return response
        }
        

    }catch(err){
        return err
    }
    
  }