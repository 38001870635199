import axios from "axios";
import { getTokenDecript } from "../auth/jwtUtils";

const BASEURL = process.env.REACT_APP_BASE_URL;
const CATEGORY_URL = process.env.REACT_APP_ENDPOINT_NUCLEO
const TOKEN = process.env.REACT_APP_APPLICATION_TOKEN;

export async function getCategoryList(activePage, limit, bool) {

  var finalQuery = '';
  if(bool){
    finalQuery = '?page='+activePage+'&page_size='+limit
  }

  try {
    const response = await axios.get(BASEURL+CATEGORY_URL+finalQuery, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function saveCategory(data) {
  const transformedJson = JSON.stringify(data)
  try {
    const response = await axios.post(BASEURL+CATEGORY_URL, transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 201) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function getCategoryParams(name, activePage, limit, bool){

  var finalQuery = '';
  if(bool){
    finalQuery = '&page='+activePage+'&page_size='+limit
  }

  try {
    const response = await axios.get(BASEURL+CATEGORY_URL+'?name='+name+finalQuery, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function updateCategory(id,data){
  const transformedJson = JSON.stringify(data)
  try {
    const response = await axios.put(BASEURL+CATEGORY_URL+id+'/', transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
  
}

export async function deleteCategory(id){
  try {
    const response = await axios.delete(BASEURL+CATEGORY_URL+id, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 204) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}