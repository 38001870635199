/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// reactstrap components
import { Row, Col, Nav, NavItem } from "reactstrap";

import { SocialIcon } from 'react-social-icons'

const Footer = () => {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            © {new Date().getFullYear()}{" "}
            <a
              className="font-weight-bold ml-1"
              href="https://portal.pi.gov.br/pge/"
              rel="noopener noreferrer"
              target="_blank"
            >
              PGE - PI. Todos os Direitos Reservados.
            </a>
          </div>
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
            <NavItem>
              <SocialIcon style={{ width: '40px', height: '40px', marginRight: '5px' }} url="https://twitter.com/pgepioficial" target="_blank" className='socialMediaIcon'/>
            </NavItem>

            <NavItem>
              
              <SocialIcon style={{ width: '40px', height: '40px', marginRight: '5px' }} url="https://instagram.com/pgepiaui/" target="_blank" className='socialMediaIcon'/>
              
            </NavItem>

            <NavItem>
              <SocialIcon style={{ width: '40px', height: '40px', marginRight: '5px' }} url="https://youtube.com/channel/UCUvn6mz9Su40_xqyZjK5Onw" target="_blank" className='socialMediaIcon'/>
            </NavItem>

          </Nav>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
