import classNames from "classnames";
import { NavLink as NavLinkRRD } from "react-router-dom";
import { Collapse, NavItem, NavLink } from "reactstrap";

function Submenu({
    prop,
    key,
    closeCollapse,
    openSidebar,
    closeSidebar,
    sidebarCollapseActive,
    submenuCollapsed,
    setSubmenuCollapsed,
    toggleSubmenu,
    submenuCollapseActive
}) {
    const handleMouseOver = () => {
        if (sidebarCollapseActive && submenuCollapseActive === false) {
            openSidebar();
            setSubmenuCollapsed(false);
        } else if (sidebarCollapseActive && submenuCollapseActive === true) {
            openSidebar();
        }
    }

    const handleMouseOut = () => {
        if (sidebarCollapseActive && submenuCollapseActive === false) {
            setSubmenuCollapsed(true);
            closeSidebar();
        } else if (sidebarCollapseActive && submenuCollapseActive === true) {
            closeSidebar();
        }
    }

    return (
        <>
            <NavItem className={classNames({ "menu-open": !submenuCollapsed })}>
                <NavLink
                    className="dropdown-toggle"
                    onClick={toggleSubmenu}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                >
                    <i className={prop.icon} />
                    <span className="nav-link-text">{prop.name}</span>
                </NavLink>
            </NavItem>
            <Collapse
                nav
                isOpen={!submenuCollapsed}
                className={classNames({ "mb-1": !submenuCollapsed }, "ml-3")}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                {
                    prop.submenuItems.map((item) => (
                        <NavItem>
                            <NavLink
                                key={key}
                                to={prop.layout + item.path}
                                tag={NavLinkRRD}
                                onClick={closeCollapse}
                            >
                                <i className={item.icon} />
                                {item.name}
                            </NavLink>
                        </NavItem>
                    ))
                }
            </Collapse>
        </>
    );
}

export default Submenu;