import axios from "axios";
import { getTokenDecript } from "../auth/jwtUtils";

const BASEURL = process.env.REACT_APP_BASE_URL;
const SUBSUBJECT_URL = process.env.REACT_APP_ENDPOINT_SUBSUBJECTS
const TOKEN = process.env.REACT_APP_APPLICATION_TOKEN;
const USER_TOKEN = process.env.REACT_APP_TOKEN

export async function getSubSubjectsByIdSubject(id) {
  try {
    const response = await axios.get(BASEURL+SUBSUBJECT_URL+'?subject='+id, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function getSubSubjectList(activePage, limit, bool) {

  var finalQuery = '';
  if(bool){
    finalQuery = '?page='+activePage+'&page_size='+limit
  }

  try {
    const response = await axios.get(BASEURL+SUBSUBJECT_URL+finalQuery, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function getSubsubjectParams(params, activePage, limit, bool) {


  var finalQuery = '';
  if(bool){
    finalQuery = '&page='+activePage+'&page_size='+limit
  }

  try {
    const response = await axios.get(BASEURL+SUBSUBJECT_URL+'?name='+params.name+'&subject='+params.subject+'&description='+params.description+finalQuery, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function saveSubsubject(params){
  const transformedJson = JSON.stringify(params)
  try {
    const response = await axios.post(BASEURL+SUBSUBJECT_URL, transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 201) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function updateSubsubject(id, data){
  const transformedJson = JSON.stringify(data)
  try {
    const response = await axios.put(BASEURL+SUBSUBJECT_URL+id+'/', transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function deleteSubsubject(id){
  try {
    const response = await axios.delete(BASEURL+SUBSUBJECT_URL+id+'/', {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 204) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}