import {
    Card,
    CardHeader,
    Label,
    Media,
    Table,
    Container,
    Row,
  } from "reactstrap";

import { Tag, Popover, Whisper, Checkbox, CheckboxGroup, Badge, Radio, RadioGroup, Button, Input, AutoComplete, CheckPicker, Stack, SelectPicker  } from 'rsuite';


import React, { useState, useEffect } from "react";

import PlusIcon from '@rsuite/icons/Plus';
import { IconButton } from 'rsuite';
        
  
import { getUsersList } from "services/userService";
import { getGroups } from "services/groupsService";
import { getCategoryList } from "services/categoryService";

import { CustomLoadingOverlay, } from '../../components/Utils/nodata'
import { CustomNoRowsOverlay } from '../../components/Utils/nodata'

import { useParams, Navigate, NavLink  } from "react-router-dom";

import 'rsuite/InlineEdit/styles/index.css';
import { updateUser } from "services/userService";
import { MessageBox, messagesContent } from "../../components/Utils/MessageBox"
import { color } from "framer-motion";

const tooltip = (message) => {
    return (
        <Popover>
             {message}
        </Popover>
    )}

const messageUpdateSuccess = MessageBox("success", "Sucesso", messagesContent.user.messageUpdateSuccess)
const messageUpdateError = MessageBox("error", "Erro", messagesContent.user.messageUpdateError)

export default function Users() {

    //teste nova table
    const [resetTable, setResetTable] = useState([]);
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [openPopover, setOpenPopover] = useState([]);

    const [openPopoverCategory, setOpenPopoverCategory] = useState([false, -1]);
    
    const [lostConnection, setLostConnection] = useState(false);

    const [openNotification, setOpenNotification] = useState([false, -1]);

    const [allgroups, setAllGroups] = useState([]);

    const [nucleo, setNucleo] = useState([])

    // search fields states
    const [nameSearchUser, setNameSearchUser] = useState("")
    const [statusSearchUser, setStatusSearchUser] = useState("")
    const [groupSearchUser, setGroupSearchUser] = useState([])
    const [nucleoSearchUser, setNucleoSearchUser] = useState("")


    // função que inicializa a lista de usuários
    useEffect(() => {
        const fetchData = async () => {
            try{
                const response = await getUsersList();
                if (response.status === 200) {
                    setData(response.data.results.map((user) => {
                      return {
                        id: user.id,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        email: user.email,
                        groups: user.groups.map((group) => ({
                          id: group.id,
                          name: group.name
                        })),
                        is_active: user.is_active,
                        is_staff: user.is_staff,
                        username: user.username,
                        phone: user.profile?.phone || null,
                        social_number: user.profile?.social_number || null, 
                        type: user.profile?.type || null,
                        category: user.profile?.category || null 
                      };
                    }));
                    setResetTable(
                        response.data.results.map((user) => {
                            return {
                              id: user.id,
                              first_name: user.first_name,
                              last_name: user.last_name,
                              email: user.email,
                              groups: user.groups.map((group) => ({
                                id: group.id,
                                name: group.name
                              })),
                              is_active: user.is_active,
                              is_staff: user.is_staff,
                              username: user.username,
                              phone: user.profile?.phone || null,
                              social_number: user.profile?.social_number || null, 
                              type: user.profile?.type || null,
                              category: user.profile?.category || null 
                            };
                          })
                    );
                    setIsLoading(false);
                }else{
                    console.log(response)
                }
            }catch(e){
                console.log(e)
            }
        }
        fetchData();
    }, []);

    // função que inicializa a lista de grupos
    useEffect(() => {
        const fetchData = async () => {
            try{
                const response = await getGroups();
                if(response.status === 200){
                    setAllGroups(response.data.results.map((group) => { return {id: group.id, name: group.name, permissions: group.permissions.map((permission) => {
                        return {
                            id: permission.id,
                            codename: permission.codename
                        }
                    })}}));
                }else{
                    console.log(response)
                }
            }catch(e){
                console.log(e)
            }
        }
        fetchData();
    }, []);

    // funcao que inicializa a lista de nucleos
    useEffect(() => {
        const fetchData = async () => {
            try{
                const response = await getCategoryList();
                if(response.status === 200){
                    setNucleo(response.data.results.map((category) => { return {id: category.id, name: category.name}}));
                }else{
                    console.log(response)
                }
            }catch(e){
                console.log(e)
            }
        }
        fetchData();
    }, []);

    //funcao que retorna o nome de acordo com a categoria
    const getCategoryName = (category) => {
        for (let i = 0; i < nucleo.length; i++) {
            if(nucleo[i].id === category){
                return nucleo[i].name;
            }
        }
    }

    const handleClearSearch = () => {
        setNameSearchUser("")
        setStatusSearchUser("")
        setGroupSearchUser([])
        setNucleoSearchUser("")
    }

    const searchParams = async () => {
        setData(resetTable.filter((user) => {
            if(nameSearchUser !== ""){
                if(user.email.toLowerCase() == (nameSearchUser.split(' - ')[0].toLowerCase())){
                    return user;
                }
            }else{
                return user;
            }
        }).filter((user) => {
            if(statusSearchUser !== ""){
                if(user.is_active.toString() === statusSearchUser){
                    return user;
                }
            }else{
                return user;
            }
        }).filter((user) => {
            if(groupSearchUser.length > 0){
                for (let i = 0; i < groupSearchUser.length; i++) {
                    if(user.groups.map((group) => {return group.id}).includes(groupSearchUser[i])){
                        return user;
                    }
                }
            }else{
                return user;
            }
        }).filter((user) => {
            if(nucleoSearchUser !== ""){
                if(user.category === nucleoSearchUser){
                    return user;
                }
            }else{
                return user;
            }
    }))
    }

    return(
        <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            <Container fluid>
            <div className="header-body">
            </div>
            <div className="bg-white p-4 rounded">
                <h3 className="mb-4 mt-2 ml-1">Usuários</h3>
                <div className="row">
                    <div className="col-6">
                        <Label>Nome</Label><AutoComplete data={[...resetTable.map((user) => {return user.email + ' - ' + user.first_name + " " + user.last_name})]} placeholder="Digite o nome do usuário" value={nameSearchUser} onChange={setNameSearchUser}></AutoComplete>
                    </div>
                    <div className="col-3">
                        <Label>Status</Label>
                        <SelectPicker placeholder="Selecione o status" data={[{label: 'Ativo', value: 'true'}, {label: 'Desabilitado', value: 'false'}]} style={{ width: '100%' }} value={statusSearchUser} onChange={setStatusSearchUser}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-3">
                        <Label>Grupo</Label>
                        <CheckPicker placeholder="Selecione o grupo" data={allgroups.map((group) => {return {label: group.name, value: group.id}})} style={{ width: '100%' }} value={groupSearchUser} onChange={setGroupSearchUser} />
                    </div>
                    <div className="col-3">
                        <Label>Núcleo</Label>
                        <SelectPicker placeholder="Selecione o núcleo" data={nucleo.map((category) => {return {label: category.name, value: category.id}})} style={{ width: '100%' }} value={nucleoSearchUser} onChange={setNucleoSearchUser}/>
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <Button color="warning" className="mr-3" size="md" onClick={handleClearSearch}><i class="fa-solid fa-trash mr-2"></i>Limpar</Button>
                    <Button color="primary" size="md" onClick={searchParams}><i class="fa-solid fa-magnifying-glass mr-2" ></i>Pesquisar</Button>
                </div>
            </div>
            </Container>
        </div>
        
        <Container className="mt--7" style={{ minHeight: '70vh' }} fluid>

            <Row>
                <div className="col">
                    <Card className="shadow">
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">Email</th>
                                <th scope="col">Grupos</th>
                                <th scope="col">Núcleo</th>
                                <th scope="col">Status</th>
                                <th scope="col">Ações</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!isLoading &&
                                data.map((u) => (
                                    <React.Fragment key={u.id} style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    <tr >
                                            <th scope="row">
                                            <Media>
                                                {u.first_name + " " + u.last_name}
                                            </Media>
                                            </th>
                                            <td>
                                                {u.email}
                                            </td>
                                            <td style={{ minWidth: '300px' }}>
                                                {
                                                    u.groups.length === 0 ? <Tag color="gray">Sem grupo</Tag> :
                                                    u.groups.map((group) => {
                                                        return (
                                                            <Tag style={{ backgroundColor: '#94a3b8', color: 'white' }}>{group.name}</Tag>
                                                        )
                                                    })
                                                }
                                            </td>
                                            <td>{ u.category != null ?
                                                <Tag style={{ backgroundColor: '#94a3b8', color: 'white' }}>
                                                    {getCategoryName(u.category)}
                                                </Tag>

                                                : <Tag color="gray">Sem núcleo</Tag>
                                            }
                                            </td>
                                            <td>
                                                {u.is_active ? <><i class="fa-solid fa-circle" style={{ color: 'green'}}></i>  Ativo</> : 
                                                <><i class="fa-solid fa-circle" style={{ color: '#ea580c', marginRight: '2px'}}></i>Desabilitado</>}
                                            </td>
                                            <td>
                                                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip("Ações de usuário")}>
                                                    <NavLink to={`/painel-de-controle/usuarios/${u.id}`}>
                                                        <IconButton icon={<i class="fa-solid fa-user-pen"></i>}  />
                                                    </NavLink>
                                                </Whisper>
                                            </td>
                                    </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                        {
                            isLoading && 
                            <div style={{ width: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CustomLoadingOverlay />
                            </div>
                        }{
                            data.length === 0 && !isLoading &&
                            <div className="mb-4 mt-4 w-100 d-flex align-items-center justify-content-center flex-column" >
                                {CustomNoRowsOverlay()}
                            </div>
                        }
                    </Card>
                </div>
            </Row>
        </Container>

        </>
    )
}