import React, { useEffect, useState } from "react";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Input,
    FormGroup,
    Form,
    Container,
    Row,
    Col,
    Label
  } from "reactstrap";

import { useNavigate, useLocation, useParams, Navigate, NavLink  } from "react-router-dom";

import { Placeholder, Whisper, IconButton, Popover, Tag, TagGroup, TagPicker, SelectPicker, useToaster, Modal  } from 'rsuite';

import { getUser } from "services/userService";

import { getGroups } from "services/groupsService";

import Drawer from 'rsuite/Drawer';

import NotFound from 'views/pages/notfound';
import UserHeader from "components/Headers/UserHeader";
import { getCategoryList } from "services/categoryService";
import { updateUser } from "services/userService";

import { MessageBox, messagesContent } from "../../components/Utils/MessageBox"
import ProtectedRoute from "auth/protectedRoute";

const tooltip = (message) => {
    return (
        <Popover>
             {message}
        </Popover>
    )}


const messageUpdateError = MessageBox("error", "Erro", messagesContent.user.messageUpdateError)
const messageUpdateSuccess = MessageBox("success", "Sucesso", messagesContent.user.messageUpdateSuccess)

export default function UniqUser() {

    const navigate = useNavigate();

    const location = useLocation();

    const processParam = useParams();

    const toaster = useToaster();

    const [user, setUser] = useState();
    const [userCategory, setUserCategory] = useState();
    const [userGroup, setUserGroup] = useState();

    const [openEdit, setOpenEdit] = useState(false);
    const handleEditModalClose = () => setOpenEdit(false);
    const handleEditModalOpen = () => setOpenEdit(true);

    const [statusPage, setStatusPage] = useState(1);

    // category
    const [data, setData] = useState([])
    // groups
    const [dataGroup, setDataGroup] = useState([])

    const [valueGroup, setValueGroup] = useState([]);
    const [valueCategory, setValueCategory] = useState([]);

    // controla modal
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalClose = () => setModalOpen(false);
    const handleModalOpen = () => setModalOpen(true);

    // update user
    const handleUpdate = async () => {

        if(valueCategory?.length == 0){
            setValueCategory("");
        }

        const newData = {first_name: user.first_name, last_name: user.last_name, username: user.username, email: user.email,
            is_staff: user.is_staff, is_active: user.is_active, groups_ids: valueGroup,
            phone: "", social_number: "", type: "", category: valueCategory
        }
        
        const response = await updateUser(user.id, newData);

        if(response.status === 200){
            handleEditModalClose();
            toaster.push(messageUpdateSuccess, {duration: 5000 })
            setOpenEdit(false);
            setTimeout(() => {
                window.location.reload();
            }, 800);
        }else{
            toaster.push(messageUpdateError, {duration: 5000 })
        }
    }

    // carregar informações do usuário
    React.useEffect(() => {
        // verifica se o formato do processo é valido
        if (processParam.id.length == RegExp('^[0-9]{1,}$')) {
            setStatusPage(0);
        }else{
            setStatusPage(1);
        }

        //busca usuario
        const getProcess = async () => {
            const response = await getUser(processParam.id);
            if (response.status === 200) {
                const data = response.data;
                if(data == null){
                    setStatusPage(0);
                }else{
                    setUser(data);
                    setUserGroup(data.groups);
                }
            }
            else {
                setStatusPage(0);
            }
        }
        getProcess();
    }, [])

    // carrega informações do núcleo
    useEffect(() => {
        console.log(user)
        if(user){
            const getNucleo = async () => {
                const response = await getCategoryList();
                if (response.status === 200) {
                    const res = response.data.results;
                    setData(res.map((category) => {
                        return {
                            label: category.name,
                            value: category.id
                            }
                            }));
                    if(res == null){
                        //setStatusPage(0);
                    }else{
                        setUserCategory( res.filter((category) => category.id == user.profile.category));
                        setValueCategory(user.profile.category);
                        console.log(valueCategory)
                    }
                }
                else {
                    setStatusPage(0);
                }
            }
            getNucleo();
        }
    }, [user]) 
    
    // carrega informação dos grupos
    useEffect(() => {
        const loadGroups = async () => {
                const response = await getGroups();
                if (response.status === 200) {
                    const res = response.data.results;
                    setDataGroup(res.map((group) => {
                        return {
                            label: group.name,
                            value: group.id}
                            }));
                    // if(res == null){
                    //     //setStatusPage(0);
                    // }else{
                    //     setUserCategory( data.filter((group) => category.id == user.profile.category));
                    //     setValueCategory(user.profile.category);
                    //     console.log(valueCategory)
                    // }
                }
                else {
                    setStatusPage(0);
                }
            }
            loadGroups();
    }, [])

    // inicializa o valor dos grupos
    useEffect(() => {
        if(user){
            setValueGroup(user.groups.map((group) => group.id));
        }
    }, [userGroup])

    //desabilitar o usuario
    const handleDisableorEnableUser = async () => {
        console.log(user)
        const newData = {first_name: user.first_name, last_name: user.last_name, username: user.username, email: user.email,
            is_staff: user.is_staff, is_active: !user.is_active, groups_ids: userGroup.map((group) => group.id),
            phone: "", social_number: "", type: "", category: userCategory.profile?.category
        }
        
        const response = await updateUser(user.id, newData);

        if(response.status === 200){
            handleEditModalClose();
            toaster.push(messageUpdateSuccess, {duration: 5000 })
            setOpenEdit(false);
            setTimeout(() => {
                window.location.reload();
            }, 800);
        }else{
            toaster.push(messageUpdateError, {duration: 5000 })
        }
    }

    return (
        <>

            <div className="head bg-gradient-info pb-8 pt-5 pt-md-8">

            </div>

            <Container className="mt--7" fluid>
            {
                statusPage == 0 && <Row>
                    <NotFound />
                </Row>
            }
            {
                statusPage == 1 && 
                
                <>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                <Col xs="8">
                                </Col>
                                <Col className="text-right" xs="4">
                                    {/* <div className="d-flex flex-row rs-flex-box-grid-space-between"> */}
                                        <div>
                                            <ProtectedRoute permissionsAccepted={['change_user']} mode={'component'}>
                                                <Whisper  placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip("Editar informações")}>
                                                    <IconButton style={{ backgroundColor: "#0369a1", color: "white", marginRight: '8px' }} onClick={handleEditModalOpen} icon={<i className="fas fa-edit"></i>}/>
                                                </Whisper>
                                            </ProtectedRoute>
                                            {/* <Button color='danger' size="sm">
                                                <i className="fas fa-trash"></i>
                                            </Button> */}

                                            <ProtectedRoute permissionsAccepted={['delete_user']} mode={'component'}>
                                            {user?.is_active ?
                                                <Whisper  placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip("Desabilitar usuário")}>
                                                    <IconButton onClick={handleModalOpen} style={{ backgroundColor: "#f87171", color: "white" }} icon={<i class="fa-solid fa-user-slash"></i>}  />
                                                </Whisper> : 
                                                <Whisper  placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip("Habilitar usuário")}>
                                                    <IconButton onClick={handleModalOpen} style={{ backgroundColor: "#0d9488", color: "white" }} icon={<i class="fa-solid fa-user-check"></i>}  />
                                                </Whisper>
                                            }
                                            </ProtectedRoute>
                                        </div>
                                    {/* </div> */}
                                </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                <h6 className="heading-small text-muted mb-4">
                                    Informações do usuário
                                </h6>
                                <div className="pl-lg-4">
                                    <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-username"
                                        >
                                            Usuário
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            value={user?.username}
                                            id="input-username"
                                            disabled={true}
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-email"
                                        >
                                            E-mail
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-email"
                                            value={user?.email}
                                            type="email"
                                            disabled={true}
                                        />
                                        </FormGroup>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-first-name"
                                        >
                                            Primeiro nome
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            // defaultValue={getAdditionalInfoDescript().firstName}
                                            id="input-first-name"
                                            value={user?.first_name}
                                            disabled={true}
                                            type="text"
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-last-name"
                                        >
                                            Último nome
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-last-name"
                                            type="text"
                                            value={user?.last_name}
                                            disabled={true}
                                        />
                                        </FormGroup>
                                    </Col>
                                    </Row>
                                </div>
                                <hr className="my-4" />
                                {/* Address */}
                                <h6 className="heading-small text-muted mb-4">
                                    Informações no sistema
                                </h6>
                                <div className="pl-lg-4">
                                    <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-first-name"
                                        >
                                            Grupos
                                        </label>
                                        <div style={{ display: 'flex', marginLeft: '10px' }}>
                                       
                                        {

                                            user?.groups.length > 0 ?
                                            user?.groups.map((group) => {
                                                return (
                                                    <Tag size="lg" style={{ backgroundColor: '#94a3b8', color: 'white', marginRight: '5px' }}>{group.name}</Tag>
                                                )
                                            }) : <>Sem grupo</>
                                        }
                                        </div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-first-name"
                                        >
                                            Núcleo
                                        </label>
                                        <div style={{ display: 'flex', marginLeft: '10px' }}>
                                        {
                                            userCategory?.length > 0 ?
                                            userCategory?.map((category) => {
                                                return (
                                                    <Tag style={{ backgroundColor: '#94a3b8', color: 'white', marginRight: '5px' }} size="lg">{category.name}</Tag>
                                                )
                                            }) : <>Sem núcleo</>
                                        }
                                        </div>
                                        </FormGroup>
                                    </Col>
                                    </Row>
                                </div>
                                <hr className="my-4" />
                                {/* Description */}
                                </Form>
                            </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
            </Container>

            <Drawer style={{ minHeight: '100vh' }} backdrop="static" open={openEdit} onClose={handleEditModalClose}>
                <Drawer.Header className="pt-4">
                    <h4>Edição usuário</h4>
                </Drawer.Header>
                <Drawer.Body>
                        <Row className="mb-4">
                            <h4>Username</h4>
                            <Input placeholder="Digite o Nº do processo" value={user?.username} disabled={true} id="masked-input"></Input>
                        </Row>
                        <Row className="mb-4">
                            <h4>Email</h4>
                            <Input id="select-subassunto" value={user?.email} disabled={true} placeholder="Selecione um Subassunto" style={{ width: "100%" }}>
                                
                            </Input>
                        </Row>
                        <Row className="mb-4">
                            <h4>Primeiro nome</h4>
                            <Input id="select-nucleo" value={user?.first_name} disabled={true} style={{ width: "100%" }}>
                                
                            </Input>
                        </Row>
                        <Row className="mb-4">
                            <h4>Último nome</h4>
                            <Input id="select-assunto" value={user?.last_name} disabled={true} style={{ width: "100%" }}>
                                
                            </Input>
                        </Row>
                        <Row className="mb-4">
                            <h4>Grupos</h4>
                            <TagPicker width={'100px'} placement={'top'} placeholder="Selecionar grupo" data={dataGroup} value={valueGroup} onChange={setValueGroup} style={{ width: "100%" }} />
                            {/* <SelectPicker width={'100px'} placement={'top'} placeholder="Selecionar grupo" data={dataGroup} value={valueGroup} onChange={setValueGroup} style={{ width: "100%" }} /> */}
                        </Row>
                        <Row className="mb-4">
                            <h4>Núcleo</h4>
                            <SelectPicker placement={'top'} placeholder="Selecionar categoria" data={data} value={valueCategory} onChange={setValueCategory} style={{ width: "100%" }} />
                        </Row>
                        <Row>
                            <Button onClick={handleUpdate} color="primary" className="position-absolute bottom-0 end-0 right-0 mr-5 mb-4">Atualizar</Button>
                        </Row>
                </Drawer.Body>
            </Drawer>

            <Modal open={modalOpen} onClose={handleModalClose}>
                <Modal.Header>
                <Modal.Title>{user?.is_active ? <>Desabilitar</> : <>Habilitar</>} usuário</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Ao {user?.is_active ? <>Desabilitar</> : <>Habilitar</>} o usuário, ele não poderá mais acessar o sistema. Deseja continuar?
                </Modal.Body>
                <Modal.Footer>
                <Button size="sm" onClick={handleModalClose} appearance="subtle">
                    Cancelar
                </Button>
                <Button size="sm" color="danger" onClick={handleDisableorEnableUser} appearance="primary">
                    Confirmar
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}