/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import ProtectedRoute from "auth/protectedRoute";

import Processo from "views/processes/processo";
import Category from "views/category/category";
import Subject from "views/subject/subject";
import Subsubject from "views/subsubject/subsubject";
import Grupos from "views/admin/groups";
import Users from "views/admin/user";
import Distribuicao from "views/distribuicao/distribuicao";
import Profile from "views/Profile/Profile";
import Logs from "views/admin/logs";
import Notifications from "views/notifications/notifications";

var routes = [
  {
    path: "dashboard",
    name: "Dashboard",
    icon: "ni ni-chart-bar-32",
    component: 
    <ProtectedRoute permissionsAccepted={['view_process', 'view_all_process']} mode={'route'}>
      <Index />
    </ProtectedRoute>,
    layout: "/",
    permissions: ['view_process', 'view_all_process'],
  },
  // submenu arrumar depois
  // {
  //   submenu: true,
  //   name: "Gerência de processos",
  //   icon: "fa-solid fa-folder",
  //   permissions: ['view_process', 'view_all_process'],
  //   layout: "gerenciar/",
  //   submenuItems: [
  //     {
  //       path: "processo/",
  //       name: "Processos",
  //       icon: "fa-regular fa-file",
  //       component:
  //       <ProtectedRoute permissionsAccepted={['view_process', 'view_all_process']} mode={'route'}>
  //         <Processo />
  //       </ProtectedRoute>,
  //       permissions: ['view_process', 'view_all_process'],
  //     }, 
  //     {
  //       path: "distribuicao",
  //       name: "Distribuição",
  //       icon: "fa-solid fa-share-alt",
  //       component:
  //         <ProtectedRoute permissionsAccepted={['deliver_process']} mode={'route'}>
  //           <Distribuicao />
  //         </ProtectedRoute>,
  //       permissions: ['deliver_process'],
  //     },
  //   ],
  // },
  {
    submenu: false,
    path: "processo",
    name: "Processos",
    icon: "fa-regular fa-file",
    component:
    <ProtectedRoute permissionsAccepted={['view_process', 'view_all_process']} mode={'route'}>
      <Processo />
    </ProtectedRoute>,
    layout: "/",
    permissions: ['view_process', 'view_all_process'],
  }, 
  {
    submenu: false,
    path: "distribuicao",
    name: "Distribuição",
    icon: "fa-solid fa-share-alt",
    component:
      <ProtectedRoute permissionsAccepted={['deliver_process']} mode={'route'}>
        <Distribuicao />
      </ProtectedRoute>,
    layout: "/",
    permissions: ['deliver_process'],
  },
  {
    submenu: false,
    path: "nucleo",
    name: "Núcleo",
    icon: "fa-brands fa-accusoft",
    component: 
    <ProtectedRoute permissionsAccepted={['view_category']} mode={'route'}>
      <Category />
    </ProtectedRoute>,
    layout: "/",
    permissions: ['view_category'],
  },
  {
    submenu: false,
    path: "assunto",
    name: "Assunto",
    icon: "fa-solid fa-spell-check",
    component: 
    <ProtectedRoute permissionsAccepted={['view_subject']} mode={'route'}>
      <Subject />
    </ProtectedRoute>,
    layout: "/",
    permissions: ['view_subject'],
  },
  {
    submenu: false,
    path: "subassunto",
    name: "SubAssunto",
    icon: "fa-solid fa-strikethrough",
    component: 
    <ProtectedRoute permissionsAccepted={['view_subsubject']} mode={'route'}>
      <Subsubject />
    </ProtectedRoute>,
    layout: "/",
    permissions: ['view_subsubject'],
  },
  {
    submenu: false,
    path: "painel-de-controle/grupos",
    name: "Grupos e permissões",
    icon: "fa-solid fa-unlock",
    component: 
    <ProtectedRoute permissionsAccepted={['add_group', 'change_group', 'delete_group', 'view_group']} mode={'route'}>
      <Grupos />
    </ProtectedRoute>,
    layout: "/",
    permissions: ['add_group', 'change_group', 'delete_group', 'view_group'],
  },
  {
    submenu: false,
    path: "painel-de-controle/usuarios",
    name: "Usuários",
    icon: "fa-solid fa-user-group",
    component: 
    <ProtectedRoute permissionsAccepted={['change_user', 'view_user', 'delete_user']} mode={'route'}>
      <Users />
    </ProtectedRoute>,
    layout: "/",
    permissions: ['change_user', 'view_user', 'delete_user'],
  },
  // {
  //   submenu: false,
  //   path: "Logs",
  //   name: "Logs",
  //   icon: "fa-solid fa-clipboard-list",
  //   component:
  //   <ProtectedRoute permissionsAccepted={['view_logentry']} mode={'route'}>
  //     <Logs />
  //   </ProtectedRoute>,
  //   layout: "/",
  //   permissions: ['view_logentry'],
  // },
];
export default routes;
